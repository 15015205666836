// Core
import React, { Component } from 'react'
// Components
import ScreenNav from '../Navigation/ScreenNav';
// Page Layout
import Page from '../Layout/Page';
// Helpers
import { checkLanguage } from '../../helpers/Functions';
// Style
import './Auth.scss';

// Globals
let LANG;

class RegisterConfirmation extends Component {
    constructor() {
        super()
        this.state = {

        }

        // get copy in chosen language
        LANG = checkLanguage();
    }

    componentDidMount = () => {
        if(!LANG) {
            this.props.history.push('/language');
        }
        if(this.props.location.state === undefined) {
            this.props.history.push('/register');
        }
    }

    render () {
        return (
            <Page className="second">
                <ScreenNav 
                    title={LANG.CONFIRMATION}
                    back={'/'}
                />
                <div className="confirmation__container">
                    <div className="confirmation__wrapper">
                        <img src="/assets/illustrations/register-confirmation.svg" alt="register confirmation"/>
                        <h1 className="confirmation__message confirmation__message--state-blue is-p">{LANG.REGISTRATION_CONFIRMATION}</h1>
                    </div>
                </div>
            </Page>
        )
    }
}

export default RegisterConfirmation