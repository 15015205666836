// Core
import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'
// Custom
import ReservationTableRow from './ReservationTableRow';
// Helpers
import { checkLanguage } from '../../../../helpers/Functions';
// Globals
let LANG;

class Table extends Component {
    constructor(props) {
        super(props)
        this.state = {
            tableContent: [],
            editable: false,
        }
        LANG = checkLanguage();
    }

    onVideoChat = (user) => {
        return (<Redirect to={{
            pathname: '/videochat',
            state: { user: user }
        }} />)
    }
    

    // onEdit = (id) => {
    //     if(this.refs['staticDate-'+id].style.display == 'block') {
    //         this.refs['staticDate-'+id].style.display = 'none';
    //         this.refs['staticStart-'+id].style.display = 'none';
    //         this.refs['staticEnd-'+id].style.display = 'none';
    //         this.refs['editDate-'+id].style.display = 'block';
    //         this.refs['editStart-'+id].style.display = 'block';
    //         this.refs['editEnd-'+id].style.display = 'block';
    //         this.setState({editable: true});
    //     } else {
    //         this.refs['staticDate-'+id].style.display = 'block';
    //         this.refs['staticStart-'+id].style.display = 'block';
    //         this.refs['staticEnd-'+id].style.display = 'block';
    //         this.refs['editDate-'+id].style.display = 'none';
    //         this.refs['editStart-'+id].style.display = 'none';
    //         this.refs['editEnd-'+id].style.display = 'none';
    //         this.setState({editable: false});
    //     }
    // }

    // onSave = (id) => {
        
    // }

    // onEdit = () => {
    //     // console.log(this.refs.staticStart)
    // }

    componentDidMount = () => {
        // console.log(this.props.data);
    }

    render () {
        const tableContent = this.props.data.map((data) =>
            <ReservationTableRow 
                key={data.id ? data.id : Date.now()}
                id={data.id}
                token={this.props.user.token}
                name={data.user.name}
                notes={data.user.notes}
                email={data.user.email}
                locked={data.locked}
                user_id={data.user.id ? data.user.id : data.user_id }
                date={data.date}
                start_at={data.start_at}
                end_at={data.end_at} />
        );
        return (
            <div className="table-responsive">
                <table className="table">
                    <thead>
                        <tr>
                            <th></th>
                            <th>{LANG.CLOWN_TABLE_NAME}</th>
                            <th>{LANG.CLOWN_TABLE_DATE}</th>
                            <th>{LANG.CLOWN_TABLE_STARTHR}</th>
                            <th>{LANG.CLOWN_TABLE_ENDHR}</th>
                            <th>{LANG.CLOWN_TABLE_NOTES}</th>
                            <th></th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>

                        {tableContent}

                    </tbody>
                </table>
            </div>
        )
    }
}

export default Table