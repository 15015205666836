// Core
import React from 'react';
import { Redirect, Route } from 'react-router-dom';
// Languages
import lang from '../languages/Lang';
// Config
import config from '../config/Config';
import axios from 'axios'

// Check if language selected and return selected language
export const checkLanguage = () => {
    if(!localStorage.getItem('lang')) {
        return false;
    } else {
        if(localStorage.getItem('lang') === 'fr') {
            return lang.fr;
        } else {
            return lang.nl;
        }
    }
}

// Check if user logged in and return json object if true
export const checkAuth = () => {
    if(!localStorage.getItem('login')) {
        return false;
    } else {
        return JSON.parse(localStorage.getItem('login'));
    }
}

// Get all clowns
export const getClowns = () => {
    return axios.get(config.server+'api/clowns')
    .then(function (response) {
        return response.data;
    })
    .catch(function (error) {
        // console.log(error);
    });
}

// Get clowns with video chat active
export const getVideoChatClowns = () => {
    return axios.get(config.server+'api/video-chat-clowns')
    .then(function (response) {
        return response.data;
    })
    .catch(function (error) {
        // console.log(error);
    });
}

// Get clown
export const getClown = (id) => {
    return axios.get(config.server+'api/clowns/'+id)
    .then(function (response) {
        return response.data;
    })
    .catch(function (error) {
        // console.log(error);
    });
}

// Get videos
export const getVideos = () => {
    return axios.get('https://www.googleapis.com/youtube/v3/search?key='+config.youtube_key+'&channelId='+config.channel_id+'&part=snippet,id&type=video&order=date&maxResults=50')
    .then(function (response) {
        return response.data;
    })
    .catch(function (error) {
        // console.log(error);
    });
}

// Get user reservation
export const getUserReservation = (id) => {
    return axios.get(config.server+'api/users/'+id+'/reservation')
    .then(function (response) {
        return response.data;
    })
    .catch(function (error) {
        return error.response.data;
    });
}

// Get users reservation by clown
// export const getReservationsByClown = (id, token) => {
//     let options = {
//         method: 'GET',
//         url: config.server+'api/clowns/'+id+'/reservation/',
//         headers: {
//             'Authorization': 'Bearer ' + token,
//             'Content-Type': 'application/json'
//         },
//         json: true
//     };
//     return axios(options)
//     .then(function (response) {
//         return response.data;
//     })
//     .catch(function (error) {
//         return error.response.data;
//     });
// }

// Get user reservation
export const getReservationsByClownAndDate = (id, date) => {
    return axios.get(config.server+'api/clowns/'+id+'/reservation/'+date)
    .then(function (response) {
        return response.data;
    })
    .catch(function (error) {
        return error.response.data;
    });
}

// //get all reservations
// export const getAllReservationsByDate = (k, date) => {
//     var reservations;
//     var i;
//     for (i = 8; i < k; i++)
//     {
//         // console.log("getting reservations for clown " + i + " on " + date);
//         return axios.get(config.server+'api/clowns/'+i+'/reservation/'+date)
//         .then(function (response) {
//             // console.log("got a reservation");
//             reservations = reservations.push(response.data);
//             // console.log(reservations);
//         })
//         .catch(function (error) {
//             return error.response.data;
//         });
//     }
//     return reservations;
// }

// Get users reservations by clown between dates
export const getReservationsByClownBetweenDates = (id, token) => {
    let options = {
        method: 'GET',
        url: config.server+'api/clowns/'+id+'/reservation',
        headers: {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json'
        },
        json: true
    };
    return axios(options)
    .then(function (response) {
        return response.data;
    })
    .catch(function (error) {
        return error.response.data;
    });
}

// Store reservation
export const storeReservation = (data, token) => {
    let options = {
        method: 'POST',
        url: config.server+'api/users/reservation',
        data: data,
        headers: {
            'Authorization': 'Bearer ' + token,
        },
        json: true
    };
    return axios(options)
    .then(function (response) {
        return response.data;
    })
    .catch(function (error) {
        return error;
    });
}

// Get all guestbook messages
export const getGuestbookMessages = (token) => {
    let options = {
        method: 'GET',
        url: config.server+'api/users/guestbook',
        headers: {
            'Authorization': 'Bearer ' + token,
        },
        json: true
    };
    return axios(options)
    .then(function (response) {
        return response.data;
    })
    .catch(function (error) {
        return error.response.data;
    });
}

// Get user guestbook messages
export const getUserGuestbookMessages = (id, token) => {
    let options = {
        method: 'GET',
        url: config.server+'api/users/guestbook/'+id,
        headers: {
            'Authorization': 'Bearer ' + token,
        },
        json: true
    };
    return axios(options)
    .then(function (response) {
        return response.data;
    })
    .catch(function (error) {
        return error.response.data;
    });
}

// Get user role
export const getUserRole = (id) => {
    return axios.get(config.server+'api/role/'+id)
    .then(function (response) {
        return response.data;
    })
    .catch(function (error) {
        return error.response.data;
    });
}

// Check if user still active
export const isActive = (data, token) => {
    let options = {
        method: 'POST',
        url: config.server+'api/users/active',
        data: data,
        headers: {
            'Authorization': 'Bearer ' + token,
        },
        json: true
    };
    return axios(options)
    .then(function (response) {
        return response.data;
    })
    .catch(function (error) {
        return error.response.data;
    });
}

// Check if user has reservation in the past
export const deleteReservationInThePast = (data, token) => {
    let options = {
        method: 'POST',
        url: config.server+`api/users/${data.user_id}/reservation/delete`,
        data: data,
        headers: {
            'Authorization': 'Bearer ' + token,
        },
        json: true
    };
    return axios(options)
    .then(function (response) {
        return response.data;
    })
    .catch(function (error) {
        return error.response.data;
    });
}

// Delete reservation
export const destroyReservation = (data, token) => {
    let options = {
        method: 'POST',
        url: config.server+`api/users/reservation/${data.reservation_id}/delete`,
        data: data,
        headers: {
            'Authorization': 'Bearer ' + token,
        },
        json: true
    };
    return axios(options)
    .then(function (response) {
        return response.data;
    })
    .catch(function (error) {
        return error.response.data;
    });
}

// Update reservation
export const updateReservation = (id, data, token) => {
    let options = {
        method: 'POST',
        url: config.server+'api/users/reservation/'+id+'/update',
        data: data,
        headers: {
            'Authorization': 'Bearer ' + token,
        },
        json: true
    };
    return axios(options)
    .then(function (response) {
        return response.data;
    })
    .catch(function (error) {
        return error.response.data;
    });
}

// // Check is component is ready
// export const isComponentReady = (lang) => {
//     if(lang) {
//         return true;
//     }
//     return false;
// }

export const isObjEmpty = (obj) => {
    for(var key in obj) {
        if(obj.hasOwnProperty(key))
            return false;
    }
    return true;
}

// Protected user routes
export const PrivateUserRoute = ({ component: Component, ...rest }) => (
    <Route
        {...rest}
        render={props => 
            localStorage.getItem('login') ? (
                <Component {...props} />
            ) : (
                <Redirect 
                    to={{
                        pathname: '/login',
                        state: { from: props.location }
                    }}
                />
            )
        }
    />
);

// Protected clown routes
export const PrivateClownRoute = ({ component: Component, ...rest }) => (
    <Route
        {...rest}
        render={props => 
            localStorage.getItem('loggedIn') ? (
                <Component {...props} />
            ) : (
                <Redirect 
                    to={{
                        pathname: '/clown/login',
                        state: { from: props.location }
                    }}
                />
            )
        }
    />
);

// Protected clown and kids routes
export const PrivateRoute = ({ component: Component, ...rest }) => (
    <Route
        {...rest}
        render={props => 
            localStorage.getItem('loggedIn') || localStorage.getItem('login') ? (
                <Component {...props} />
            ) : (
                <Redirect 
                    to={{
                        pathname: '/login',
                        state: { from: props.location }
                    }}
                />
            )
        }
    />
);