// Core
import React, { Component, useState, setShow } from 'react'
import { Link, NavLink, Redirect } from 'react-router-dom'
import { toast } from 'react-toastify';
import { Dropdown, Modal, Button } from 'react-bootstrap'
import axios from 'axios'
// Helpers
import { checkLanguage, updateReservation, getClowns, checkAuth } from '../../../../helpers/Functions';
// Config
import config from '../../../../config/Config';
// Globals
let user;
let LANG;
const toastOptions = {
    autoClose: 6000,
    type: toast.TYPE.ERROR,
    position: toast.POSITION.BOTTOM_CENTER,
    draggablePercent: 60
};

class ReservationTableRow extends Component {
    constructor(props) {
        super(props)
        this.state = {
            editable: false,
            editBtn: false,
            saveBtn: true,
            clowns: [],
            date: this.props.date,
            start_at: this.props.start_at,
            end_at: this.props.end_at,
            showDialog: false,
            extraClown: null
        }

        // Create refs for map elements
        this.staticDate = React.createRef();
        this.staticStart = React.createRef();
        this.staticEnd = React.createRef();
        this.editDate = React.createRef();
        this.editStart = React.createRef();
        this.editEnd = React.createRef();
        
        // Static variables
        this.user = JSON.parse(localStorage.getItem('loggedIn')) || null;
        LANG = checkLanguage();
        // get auth user
        user = checkAuth();
        
        // bind functions to buttons and input fields
        this.onChange = this.onChange.bind(this)
    }

    onChange(e) {
        this.setState({ 
            [e.target.name]: e.target.value 
        })
    }

    onEdit = async () => {
        await this.setState({editable: !this.state.editable});
        if(this.state.editable) {
            this.setState({
                editBtn: true,
                saveBtn: false,
            });
            this.refs.staticDate.style.display = 'none';
            this.refs.staticStart.style.display = 'none';
            this.refs.staticEnd.style.display = 'none';
            this.refs.editDate.style.display = 'block';
            this.refs.editStart.style.display = 'block';
            this.refs.editEnd.style.display = 'block';
        } else {
            this.setState({
                editBtn: false,
                saveBtn: true,
            });
            this.refs.staticDate.style.display = 'block';
            this.refs.staticStart.style.display = 'block';
            this.refs.staticEnd.style.display = 'block';
            this.refs.editDate.style.display = 'none';
            this.refs.editStart.style.display = 'none';
            this.refs.editEnd.style.display = 'none';
        }
    }

    onSave = () => {
        let data = {
            date: this.state.date,
            start_at: this.state.start_at,
            end_at: this.state.end_at,
        }
        updateReservation(this.props.id, data, this.props.token).then(res => {
            this.onEdit();
        })
    }

    componentDidMount = () => {
        console.log(this.props);
        getClowns().then(res => {
            this.setState({
                clowns: res.data
            })
        });
    }

    hideDialog = () => {
    console.log("test");
        this.setState({
            showDialog: false,
        })
    }

    consoleLog = () => {
        console.log("test");
    }

    popupDialog = (clown) => {
        this.setState({
            showDialog: true,
            extraClown: clown,
        })
        console.log(clown.clown.nickname);
    }

    addClownSubmit = () => {
        console.log(this.state.extraClown.clown.user_id);

        // Get this to use later on this function
        let self = this;
        // API post request
        let options = {
                method: 'POST',
                url: config.server+'api/users/reservation',
                data: {
                    user_email: this.props.email,
                    clown_id: this.state.extraClown.clown.user_id,
                    date: this.state.date,
                    start_at: this.state.start_at,
                    end_at: this.state.end_at
                },
                headers: {
                    'Authorization': 'Bearer ' + user.token,
                },
                json: true
        };

        axios(options)
        .then(function (response) {
            // self.onResponse(response.data.success, response.data.message);
            console.log("success");
        })
        .catch(function (error) {
            console.log("something went wrong");
            //self.onResponse(error.response.data.success, Object.values(error.response.data.errors)[0], false);
        });
    }

    onResponse = (status, response) => {
        if(status) {
            // Push to confirmation screen
            this.props.history.push({pathname: '/agenda/confirmation', state: { success: true }});
        } else {
            let toastId = null
            toast.dismiss(this.toastId);
            this.toastId = toast(""+response, toastOptions)
        }
    }

    render () {
        const dropdownClowns = this.state.clowns.map((clown) =>
            <Dropdown.Item onClick={() => this.popupDialog(clown)}>{clown.clown.nickname}</Dropdown.Item>
        );
        return (
            <tr className="table__row" style={{zIndex: 9999}}>
                <Modal
                    show={this.state.showDialog}
                    onHide={this.hideDialog}
                    backdrop="static"
                    keyboard={false}
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Clown toevoegen</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        Weet je zeker dat je {this.state.extraClown ? this.state.extraClown.clown.nickname : null } wilt toevoegen aan de afspraak op {this.state.date} om {this.state.start_at}?
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={this.hideDialog}>
                        Nee
                        </Button>
                        <Button variant="primary" onClick={this.addClownSubmit}>Jazeker</Button>
                    </Modal.Footer>
                </Modal>
                <td>
                    <img src="/assets/images/kids/avatar-default-1.jpg" className="mr-2" alt=""/>
                </td>
                <td>
                    {this.props.name}
                </td>
                <td>
                    <span ref="staticDate" className="static__reservation">{this.state.date}</span>
                    {(this.props.locked !== undefined) ? <input ref="editDate" type="date" name="date" className="form-control edit__reservation" onChange={this.onChange} value={this.state.date} /> : ''}
                </td>
                <td>
                    <span ref="staticStart" className="static__reservation">{this.state.start_at}</span>
                    {(this.props.locked !== undefined) ? <input ref="editStart" type="time" name="start_at" className="form-control edit__reservation" onChange={this.onChange} value={this.state.start_at} /> : ''}
                </td>
                <td>
                    <span ref="staticEnd" className="static__reservation">{this.state.end_at}</span>
                    {(this.props.locked !== undefined) ? <input ref="editEnd" type="time" name="end_at" className="form-control edit__reservation" onChange={this.onChange} value={this.state.end_at} /> : ''}
                </td>
                <td>
                    {this.props.notes}
                </td>
                <td>
                    <Link 
                        className="btn btn-gradient-success"
                        to={{
                            pathname: '/videochat',
                            state: { user: this.props.user_id }
                        }}> Videochat 
                    </Link>
                </td>
                <td style={{zIndex: 9999, overflow: 'visible'}}>
                    <Dropdown>
                        <Dropdown.Toggle variant="success" id="dropdown-basic">
                        {LANG.CLOWN_TABLE_ADDCLOWN}
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            {dropdownClowns}
                        </Dropdown.Menu>
                    </Dropdown>
                </td>
                <td>
                    {(this.props.locked !== undefined) ? <button onClick={() => this.onEdit()} disabled={this.state.editBtn} className={this.state.editBtn ? 'btn btn-gradient-light' : 'btn btn-gradient-danger'}>{LANG.CLOWN_TABLE_EDIT}</button> : <button disable="true" className="btn btn-gradient-light">{LANG.CLOWN_TABLE_EDIT}</button>}
                </td>
                <td>
                    {(this.props.locked !== undefined) ? <button onClick={() => this.onSave()} disabled={this.state.saveBtn} className={this.state.saveBtn ? 'btn btn-gradient-light' : 'btn btn-gradient-secondary'}>{LANG.CLOWN_TABLE_SAVE}</button> : <button disable="true" className="btn btn-gradient-light">{LANG.CLOWN_TABLE_SAVE}</button>}
                </td>
            </tr>
        )
    }
}

export default ReservationTableRow