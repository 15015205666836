// Core
import React, { Component } from 'react'
import { toast } from 'react-toastify';
import axios from 'axios'
import moment from 'moment';
// Components
import ScreenNav from '../Navigation/ScreenNav';
// Page Layout
import Page from '../Layout/Page';
// Helpers
import { checkLanguage, checkAuth } from '../../helpers/Functions';
// Config
import config from '../../config/Config';
// Style
import './SendMessage.scss'
// Globals
let LANG;
let user;
const toastOptions = {
    autoClose: 6000,
    type: toast.TYPE.ERROR,
    position: toast.POSITION.BOTTOM_CENTER,
    draggablePercent: 60
};

class SendMessage extends Component {
    constructor() {
        super()
        this.state = {
            message: '',
        }

        // bind functions to buttons and input fields
        this.onChange = this.onChange.bind(this)
        this.onSubmit = this.onSubmit.bind(this)
        // get copy in chosen language
        LANG = checkLanguage();
        // get auth user
        user = checkAuth();
    }

    onChange(e) {
        this.setState({ 
            [e.target.name]: e.target.value 
        })
    }

    onSubmit(e) {
        // Prevent default
        e.preventDefault();
        // Get this to use later on this function
        let self = this;
        // API post request
        let options = {
            method: 'POST',
            url: config.server+'api/users/guestbook',
            data: {
                user_id: user.id,
                message: this.state.message
            },
            headers: {
                'Authorization': 'Bearer ' + user.token,
            },
            json: true
        };

        axios(options)
        .then(function (response) {
            self.onResponse(true);
        })
        .catch(function (error) {
            if("errors" in error.response.data) {
                self.onResponse(false, Object.values(error.response.data.errors)[0], false);
            } else if("message" in error.response.data) {
                self.onResponse(false, Object.values(error.response.data.message)[0], false);
            } else {
                self.onResponse();
            }
        });
    }

    onResponse = (status, response, data) => {
        let s = status || false;
        let r = response || "Er is iets fout gegaan";
        let d = data || false;
        if(s) {
            // Push to confirmation screen
            this.props.history.push({pathname: '/guestbook/', state: { middle: true }});
        } else {
            // console.log(toast);
            let toastId = null
            toast.dismiss(this.toastId);
            this.toastId = toast(""+r, toastOptions)
        }
    }

    componentDidMount = async () => {
        if(!LANG) {
            this.props.history.push({pathname: '/language', state: { second: true }});
        } else {
            if(!user) {
                this.props.history.push({pathname: '/login', state: { second: true }});
            } else {
                // Do what you need to do
                
            }
        }
    }
    
    render () {
        // get copy in chosen language
        return (
            <Page className="">
                <ScreenNav 
                    title={LANG.GUESTBOOK}
                    back={'/guestbook'}
                />  
                <div className="container">
                    <form className="form" noValidate onSubmit={this.onSubmit}>
                        <div className="input__field">
                            <textarea 
                                className="guestbook__message textarea"
                                name="message"
                                placeholder={LANG.WRITE_MESSAGE}
                                onChange={this.onChange}
                            ></textarea>
                            <div className="guestbook__message--background-white"></div>
                        </div>
                        <div className="app__btn__group">
                            <button 
                                className="app__btn app__btn--state-normal"
                                type="submit" >
                                {LANG.DELIVER_MESSAGE}
                            </button>
                        </div>
                    </form>
                </div>
            </Page>
        )
    }
}

export default SendMessage