// Core
import React, { Component, useState } from 'react'
import { Link } from 'react-router-dom'
import Navbar from '../Navigation/Navbar'
import Pusher from 'pusher-js';
// Page Layout
import Page from '../Layout/Page';
// Config
import config from '../../config/Config';
// Helpers
import { checkLanguage, isActive, deleteReservationInThePast } from '../../helpers/Functions';
// Style
import './Home.scss'
// Globals
let LANG;
let LangNL;

class Home extends Component {
    constructor(props) {
        super(props)
        this.state = {
            home: true,
            notification: null,
            isHoveredClown: false,
            isHoveredTipi: false,
            isHoveredBalloon: false,
            isHoveredCaravan: false,
            isHoveredParasol: false,
            isHoveredSign: false,
            refresh: false
        }
        this.handleClownHover = this.handleClownHover.bind(this);
        this.handleTipiHover = this.handleTipiHover.bind(this);
        this.handleBalloonHover = this.handleBalloonHover.bind(this);
        this.handleCaravanHover = this.handleCaravanHover.bind(this);
        this.handleParasolHover = this.handleParasolHover.bind(this);
        this.handleSignHover = this.handleSignHover.bind(this);
        // Static variables
        this.user = JSON.parse(localStorage.getItem('login')) || null;
        // Create refs for map elements
        this.pageRef = React.createRef();
        this.clownsRef = React.createRef();
        this.videosRef = React.createRef();
        this.videochatRef = React.createRef();
        this.agendaRef = React.createRef();
        this.guestbookRef = React.createRef();
        this.settingsRef = React.createRef();
        // Location state first on home page
        props.location.state = 'first';
        // Bind functions
        this.updateDimensions = this.updateDimensions.bind(this);
        // Get copy in chosen language
        LANG = checkLanguage();
        if (LANG.LOGIN == 'inloggen')
        {
            LangNL = true;
        }
        else{
            LangNL = false;
        }
    }

    componentDidMount = () => {
        // this.setState({
        //     refresh: this.props.location.state.refresh
        // })
        // if(this.props.location.state.refresh){
        //     console.log("Leonard says state: " + this.state.refresh);
        //     console.log("Leonard says: reloading");
        //     window.location.reload();
        // }
        // console.log("Leonard says state: " + this.state.refresh);
        // console.log("Leonard says: home loaded");
        var isMobile = /iPhone|iPad|iPod/i.test(navigator.userAgent);
        if(!LANG) {
            this.props.history.push('/language');
        } else {
            // Add event listener
            let self = this;
            // Check if user is still active
            this.checkIfUserActive();
            // Check if user has reservation
            this.checkIfUserHasReservation();
            // ! CONFLICT WITH ANIMATION -> FIXED? ! //
            setTimeout(function() {
                self.updateDimensions();
                self.getTheLastSelectedElement();
            }, 50)
            // ! CONFLICT WITH ANIMATION -> FIXED? ! //
            window.addEventListener("resize", this.updateDimensions);
        }
        document.querySelector('.page').classList.remove('coco');
        setTimeout(() => {
            if (isMobile){
                this.forceRedraw(document.querySelector('.first'));
            }
        }, 250)
    }
    
    // Calculate & Update state of new dimensions
    updateDimensions() {
        let clownsEl = document.querySelector('#clowns');
        let tipiEl = document.querySelector('#tipi');
        let airballoonEl = document.querySelector('#airballoon');
        let caravanEl = document.querySelector('#caravan');
        let parasolEl = document.querySelector('#parasol');
        // let tentEl = document.querySelector('#tent');
        let signEl = document.querySelector('#sign');
        let mapWidthMobile;
        let mapWidthTablet;
        let mapWidthDesktop;
        if(window.innerWidth >= 1024) {
            mapWidthDesktop = document.querySelector('.background--desktop').width;
            clownsEl.style.top = '50px';
            tipiEl.style.top = '0';
            airballoonEl.style.top = '300px';
            caravanEl.style.top = '450px';
            parasolEl.style.top = '700px';
            // tentEl.style.top = mapWidth * 2 + 'px';
            signEl.style.top = '600px';
        }
        if(window.innerWidth >= 768 && window.innerWidth < 1024) {
            mapWidthTablet = document.querySelector('.background--tablet').width;
            clownsEl.style.top = mapWidthTablet / 2 * 0.5 + 'px';
            tipiEl.style.top = mapWidthTablet / 3 * 4.5 + 'px';
            airballoonEl.style.top = mapWidthTablet / 3 * 8.5 + 'px';
            caravanEl.style.top = mapWidthTablet / 3 * 12.5 + 'px';
            parasolEl.style.top = mapWidthTablet / 3 * 17 + 'px';
            // tentEl.style.top = mapWidth * 2 + 'px';
            signEl.style.top = mapWidthTablet / 3 * 21.25+ 'px';
        }
        if(window.innerWidth < 768) {
            mapWidthMobile = document.querySelector('.background--mobile').width;
            clownsEl.style.top = mapWidthMobile * 0.5 + 'px';
            tipiEl.style.top = mapWidthMobile * 2 + 'px';
            airballoonEl.style.top = mapWidthMobile * 3.875 + 'px';
            caravanEl.style.top = mapWidthMobile * 6 + 'px';
            parasolEl.style.top = mapWidthMobile * 7.5 + 'px';
            // tentEl.style.top = mapWidth * 2 + 'px';
            signEl.style.top = mapWidthMobile * 9.5 + 'px';
        }
    }

    // get the last selected element and scroll to it
    getTheLastSelectedElement = () => {
        if(localStorage.getItem('mapPosition') !== null) {
            let element = localStorage.getItem('mapPosition');
            switch (element) {
            case 'clowns':
                this.scrollToElement(this.clownsRef);
                break;
            case 'videos':
                this.scrollToElement(this.videosRef);
                break;
            case 'videochat':
                this.scrollToElement(this.videochatRef);
                break;
            case 'agenda':
                this.scrollToElement(this.agendaRef);
                break;
            case 'guestbook':
                this.scrollToElement(this.guestbookRef);
                break;
            case 'settings':
                this.scrollToElement(this.settingsRef);
                break;
            default:
                // console.log('wrong element')
            }
        }
    }

    // Scroll to element
    scrollToElement = (element) => {
        let elementPosition = element.current.offsetTop;
        let elementHeight = element.current.clientHeight;
        this.pageRef.current.parentNode.scrollTo(0, elementPosition - elementHeight);
    }

    handleClownHover() {
        this.setState({
          isHoveredClown: !this.state.isHoveredClown
        });
    }

    handleTipiHover() {
        this.setState({
          isHoveredTipi: !this.state.isHoveredTipi
        });
    }

    handleBalloonHover() {
        this.setState({
          isHoveredBalloon: !this.state.isHoveredBalloon
        });
    }

    handleCaravanHover() {
        this.setState({
          isHoveredCaravan: !this.state.isHoveredCaravan
        });
    }

    handleParasolHover() {
        this.setState({
          isHoveredParasol: !this.state.isHoveredParasol
        });
    }

    handleSignHover() {
        this.setState({
          isHoveredSign: !this.state.isHoveredSign
        });
    }

    // Remove event listener
    componentWillUnmount() {
        window.removeEventListener("resize", this.updateDimensions);
    }

    // Store map position in localstorage
    mapPosition = (element) => {
        localStorage.setItem("mapPosition", element);
    }

    // Check if user is still active
    checkIfUserActive() {
        if(this.user) {
            let data = {id: this.user.id}
            isActive(data, this.user.token).then(res => {
                // If user is not active anymore
                if(!res.success) {
                    localStorage.removeItem('login');
                }
            })
        }
    }

    // Check if reservation is in the past and delete it
    checkIfUserHasReservation() {
        if(this.user) {
            let data = {user_id: this.user.id}
            deleteReservationInThePast(data, this.user.token).then(res => {
                // console.log(res);
            })
        }
    }

    forceRedraw(element) {

        if (!element) { return; }
    
        var n = document.createTextNode('.');
        var disp = element.style.display;  // don't worry about previous display style
    
        element.appendChild(n);
        element.style.display = 'none';
    
        setTimeout(function(){
            element.style.display = disp;
            n.parentNode.removeChild(n);
        },50); // you can play with this timeout to make it as short as possible
    }

    render () {
        // get copy in chosen language
        LANG = checkLanguage(this);
        const clownActive = this.state.isHoveredClown ? "map-element bounce" : "map-element";
        const tipiActive = this.state.isHoveredTipi ? "map-element bounce" : "map-element";
        const balloonActive = this.state.isHoveredBalloon ? "map-element bounce" : "map-element";
        const caravanActive = this.state.isHoveredCaravan ? "map-element bounce" : "map-element";
        const parasolActive = this.state.isHoveredParasol ? "map-element bounce" : "map-element";
        
        const signActive = this.state.isHoveredSign ? "map-element bounce" : "map-element";
        return (
            <Page 
                className="first">
                <div ref={this.pageRef} className="container">
                    <div className="background">
                        <img className="background--mobile" src="/assets/map/map.svg" alt="achtergrond"></img>
                        <img className="background--tablet" src="/assets/map/map-tablet.svg" alt="achtergrond"></img>
                        <img className="background--desktop" src="/assets/map/map-desktop.svg" alt="achtergrond"></img>
                    </div>
                    <header>
                        <Navbar 
                            page={this.pageRef}
                            clowns={this.clownsRef}
                            videos={this.videosRef}
                            videochat={this.videochatRef}
                            agenda={this.agendaRef}
                            guestbook={this.guestbookRef}
                            settings={this.settingsRef} />
                    </header>
                    <div className="map">
                        <div ref={this.clownsRef} className={clownActive} id="clowns" onMouseEnter={this.handleClownHover} onMouseLeave={this.handleClownHover}>
                            <Link 
                                onClick={() => this.mapPosition('clowns')} to={{ pathname: '/clowns/', state: { second: true } }}>
                                <img 
                                src={LangNL ? '/assets/map/clowns.svg' : '/assets/map/clowns.svg'} 
                                alt='ontdek de clowns'
                                />
                            </Link>
                        </div>
                        <div ref={this.videosRef} className={tipiActive} id="tipi" onMouseEnter={this.handleTipiHover} onMouseLeave={this.handleTipiHover}>
                            <Link 
                                onClick={() => this.mapPosition('videos')} to={{ pathname: '/videos/', state: { second: true } }}>
                                <img 
                                src={LangNL ? '/assets/map/tipi.svg' : '/assets/map/tipi_fr.svg'}
                                alt="bekijk onze video's" 
                                />
                            </Link>
                        </div>
                        <div ref={this.videochatRef} className={balloonActive} id="airballoon" onMouseEnter={this.handleBalloonHover} onMouseLeave={this.handleBalloonHover}>
                            <Link 
                                onClick={() => this.mapPosition('videochat')} to={{ pathname: '/videochat/', state: { second: true } }}>
                                <img 
                                src={LangNL ? '/assets/map/airballoon.svg' : '/assets/map/airballoon_fr.svg'}
                                alt="videocammen" 
                                />
                            </Link>
                        </div>
                        <div ref={this.agendaRef} className={caravanActive} id="caravan" onMouseEnter={this.handleCaravanHover} onMouseLeave={this.handleCaravanHover}>
                            <Link 
                                onClick={() => this.mapPosition('agenda')} to={{ pathname: '/agenda/', state: { second: true } }}>
                                <img 
                                src={LangNL ? '/assets/map/caravan.svg' : '/assets/map/caravan_fr.svg'}
                                alt="bekijk hier je agenda" 
                                />
                            </Link>
                        </div>
                        <div ref={this.guestbookRef} className={parasolActive} id="parasol" onMouseEnter={this.handleParasolHover} onMouseLeave={this.handleParasolHover}>
                            <Link 
                                onClick={() => this.mapPosition('guestbook')} to={{ pathname: '/guestbook/', state: { second: true } }}>
                                <img 
                                src={LangNL ? '/assets/map/parasol.svg' : '/assets/map/parasol_fr.svg'}
                                alt="bekijk het gastenboek" 
                                />
                            </Link>
                        </div>
                        <div ref={this.settingsRef} className={signActive} id="sign" onMouseEnter={this.handleSignHover} onMouseLeave={this.handleSignHover}>
                            <Link 
                                onClick={() => this.mapPosition('settings')} to={{ pathname: '/settings', state: { second: true } }}>
                                <img 
                                src={LangNL ? '/assets/map/sign.svg' : '/assets/map/sign.svg'}
                                alt="terug naar boven" 
                                />
                            </Link>
                        </div>
                    </div>
                </div>
            </Page>
        )
    }
}

export default Home