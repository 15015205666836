let lang = {
    nl: {
        // Page Titles
        "HOME": "home",
        "CLOWNS": "clowns",
        "LOGIN": "inloggen",
        "REGISTER": "account aanvragen",
        "CALENDAR": "agenda",
        "GUESTBOOK": "gastenboek",
        "CONFIRMATION": "bevestiging",
        "VIDEOS": "video's",
        "VIDEOCHAT": "videogesprek",
        "SETTINGS": "instellingen",

        // Auth
        "USER_NAME_FIELD": "Naam + voornaam kind",
        "USER_PARENT_NAME_FIELD": "Naam + voornaam ouder",
        "PASSWORD_FIELD": "Wachtwoord",
        "PASSWORD_REPEAT_FIELD": "Wachtwoord herhalen",
        "EMAIL_FIELD": "Emailadres",
        "PHONE_FIELD": "Telefoonnummer van ouder",
        "GDPR_FIELD": "Ik ga akkoord met de privacy voorwaarden.",
        "REGISTER_BTN": "Account aanvragen",
        "REGISTER_QUESTION": "Nog geen account?",
        "LOGIN_BTN": "Inloggen",
        "LOGIN_QUESTION": "Heb je al een account?",
        "REGISTRATION_CONFIRMATION": "Je aanvraag is verzonden! Je krijgt van de Cliniclowns een bevestiging binnen 48 uur.",
        "RESET_PASSWORD": "Verzend email",
        "FORGOT_PASSWORD": "Wachtwoord vergeten?",
        "RESET_PASSWORD_CONFIRMATION": "Als deze e-mail geregistreerd staat krijg je een link! Kijk zeker ook je spamfolder eens na.",
        "PASSWORD_INSECURE": "Je wachtwoord is onveilig. Gebruik ten minste 8 karakters, 1 cijfer en 1 hoofdletter.",
        "PASSWORDS_DONTMATCH": "De wachtwoorden komen niet overeen!",
        "PASS_ERROR": "Er is iets fout gegaan.",

        // Agenda
        "ABOUT_CLOWN": "Over ",
        "CLOWN_HEADER": "Maak een afspraak",
        "CLOWNS_HEADER": "Kies een clown",
        "DATE_HEADER": "Kies een datum",
        "TIME_HEADER": "Kies een moment",
        "MAKE_RESERVATION": "Maak een afspraak",
        "RESERVATION_BTN": "Afspraak bevestigen",
        "RESERVATION_CONFIRMATION": "Je afspraak staat in de agenda!",
        "CHANGE_RESERVATION_BTN": "Afspraak wijzigen",
        "DELETE_RESERVATION_BTN": "Afspraak verwijderen",
        "GETTING_HOURS": "Uren worden opgehaald...",
        "RES_TODAY": "Jouw afspraak is vandaag",
        "RES_IN": "Jouw afspraak is over ",
        "DAYS": " dagen",
        "HOURS": " uur",
        "SELECTED_CLOWN": "Gekozen clown: ",

        // Guestbook
        "ALL_MESSAGES": "Alle berichten",
        "MY_MESSAGES": "Mijn berichten",
        "SEND_MESSAGE": "Stuur een bericht",
        "WRITE_MESSAGE": "Schrijf een bericht",
        "DELIVER_MESSAGE": "Bericht verzenden",

        // Settings
        "LOGOUT_BTN": "Uitloggen",
        "LOGIN_BTN_SETTINGS": "Inloggen",
        "NOT_LOGGEDIN": "Je bent momenteel niet ingelogd.",
        "LOGGEDIN_AS": "Je bent ingelogd als ",
        "CHANGE_LANG": "De taal veranderen:",
        "CLOWN_LINK": "Clown? Klik hier",

        // Videochat
        "CALL": "Inkomende oproep!",
        "CALLING": "Belt je!",
        "ACCEPT": "Accepteer",
        "DENY": "Weiger",
        "WELCOME": "Hey! Als je een afspraak hebt kan je hier wachten. Je kan de app ook verder ontdekken, dan bel ik je op.",
        "TOO_LATE": "Oeps, te laat!  sorry, ik was nog even mijn haar aan het kammen.  Goed als ik je zo terugbel?",
        "IS_CALLING": " belt je!",
        "PUSH_NOTIFICATION_BODY": "Iemand probeert je te bellen!",

        //Videos
        "CLINICAM_VIDEOS": "App-filmpjes",
        "YOUTUBE_VIDEOS": "YouTube-filmpjes",

        //Clown admin
        "CLOWN_CAL": "Jouw agenda",
        "CLOWN_GUESTBOOK": "Gastenboek",
        "CLOWN_GB_OVERVIEW": "Overzicht",
        "CLOWN_GB_WRITTENBY": "Geschreven door ",
        "CLOWN_TABLE_EDIT": "Bewerken",
        "CLOWN_TABLE_SAVE": "Opslaan",
        "CLOWN_TABLE_NAME": "Naam",
        "CLOWN_TABLE_DATE": "Datum",
        "CLOWN_TABLE_STARTHR": "Startuur",
        "CLOWN_TABLE_ENDHR": "Einduur",
        "CLOWN_TABLE_NOTES": "Notities",
        "CLOWN_TABLE_ADDCLOWN": "Clown +",
        "CLOWN_EMPTY_CALENDAR": "Je hebt geen afspraken in je agenda.",

        //Error page
        "ERROR_NF": "Oei! Verkeerde pagina. Klik op het pijltje om terug te gaan."
    },

    fr: {
        // Page Titles
        "HOME": "Accueil",
        "CLOWNS": "Clowns",
        "LOGIN": "Enregistrer",
        "REGISTER": "S'inscrire",
        "CALENDAR": "Calendrier",
        "GUESTBOOK": "Livre d'or",
        "CONFIRMATION": "Confirmation",
        "VIDEOS": "Vidéos",
        "VIDEOCHAT": "Chat vidéo",
        "SETTINGS": "Paramètres",

        // Auth
        "USER_NAME_FIELD": "Nom + prénom d'enfant",
        "USER_PARENT_NAME_FIELD": "Nom + prénom de parent",
        "PASSWORD_FIELD": "Mot de passe",
        "PASSWORD_REPEAT_FIELD": "Répétér mot de passe",
        "EMAIL_FIELD": "Adresse e-mail",
        "PHONE_FIELD": "Numéro de téléphone du parent/tuteur",
        "GDPR_FIELD": "J'accepte les conditions de confidentialité.",
        "REGISTER_BTN": "Déposer un compte",
        "REGISTER_QUESTION": "Pas de compte?",
        "LOGIN_BTN": "Enregistrer",
        "LOGIN_QUESTION": "Avez-vous déjà un compte?",
        "REGISTRATION_CONFIRMATION": "Votre demande à été envoyé! Vous recevrez une confirmation dans les 48 prochaines heures.",
        "RESET_PASSWORD": "Envoyer un e-mail",
        "FORGOT_PASSWORD": "Vous avez oublié votre mot de passe?",
        "RESET_PASSWORD_CONFIRMATION": "Si votre adresse e-mail est enregistrée, vous recevrez un lien! Vérifiez également votre dossier spam.",
        "PASSWORD_INSECURE": "Le mot de passe n'est pas sécurisé. Veuillez utiliser au moins 8 caractères, un chiffre et une lettre majuscule",
        "PASSWORDS_DONTMATCH": "Les mots de passe ne correspondent pas!",
        "PASS_ERROR": "Un problème est survenu.",

        // Agenda
        "ABOUT_CLOWN": "À propos de ",
        "CLOWN_HEADER": "Choisis un clown",
        "DATE_HEADER": "Choisis une date",
        "TIME_HEADER": "Choisis un moment",
        "MAKE_RESERVATION": "Faire un rendez-vous",
        "RESERVATION_BTN": "Confirmer le rendez-vous",
        "RESERVATION_CONFIRMATION": "Votre rendez-vous est à l'ordre du jour!",
        "CHANGE_RESERVATION_BTN": "Changer de rendez-vous",
        "DELETE_RESERVATION_BTN": "Supprimer le rendez-vous",
        "GETTING_HOURS": "S'il vous plaît, attendez...",
        "RES_TODAY": "Votre rendez-vous est aujourd'hui!",
        "RES_IN": "Votre rendez-vous est dans ",
        "DAYS": " jours",
        "HOURS": "",
        "SELECTED_CLOWN": "Clown choisi: ",

        // Guestbook
        "ALL_MESSAGES": "Tous messages",
        "MY_MESSAGES": "Mes messages",
        "SEND_MESSAGE": "Envoyer un message",
        "WRITE_MESSAGE": "Écrire un message",
        "DELIVER_MESSAGE": "Envoyer le message",

        // Settings
        "LOGOUT_BTN": "Se déconnecter",
        "LOGIN_BTN_SETTINGS": "Se connecter",
        "LOGGEDIN_AS": "Vous êtes connecté en tant que ",
        "NOT_LOGGEDIN": "Vous n'êtes pas connecté.",
        "CHANGE_LANG": "Changer la langue: ",
        "CLOWN_LINK": "Clown? Cliquez ici",

        // Videochat
        "CALL": "Appel entrant!",
        "CALLING": "t'appelle!",
        "ACCEPT": "Accepter",
        "DENY": "Refuser",
        "WELCOME": "Hey! Si vous avez un rendez-vous, vous pouvez attendre ici. Vous pouvez également découvrir l'application plus avant et je vous appellerai.",
        "TOO_LATE": "Oups, trop tard! Désolé, je me brossais les cheveux. OK si je vous rappelle dans une minute?",
        "IS_CALLING": " t'appelle!",
        "PUSH_NOTIFICATION_BODY": "Quelqu'un essaie de t'appeler!",

        //Videos
        "CLINICAM_VIDEOS": "Vidéos de l'appli",
        "YOUTUBE_VIDEOS": "Vidéos YouTube",

        //Clown admin
        "CLOWN_CAL": "Votre agenda",
        "CLOWN_GUESTBOOK": "Livre d'or",
        "CLOWN_GB_OVERVIEW": "Aperçu",
        "CLOWN_GB_WRITTENBY": "Écrit par ",
        "CLOWN_TABLE_EDIT": "Modifier",
        "CLOWN_TABLE_SAVE": "Sauvegarder",
        "CLOWN_TABLE_NAME": "Nom",
        "CLOWN_TABLE_DATE": "Date",
        "CLOWN_TABLE_STARTHR": "Heure de début",
        "CLOWN_TABLE_ENDHR": "Heure de fin",
        "CLOWN_TABLE_NOTES": "Remarques",
        "CLOWN_TABLE_ADDCLOWN": "Clown +",
        "CLOWN_EMPTY_CALENDAR": "Vous n'avez aucun rendez-vous dans votre calendrier.",

        //Error page
        "ERROR_NF": "Oops! Mauvaise page. Cliquez sur la flèche pour revenir en arrière."
    }
}

export default lang;