// Core
import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify';
import axios from 'axios'
// Components
import ScreenNav from '../Navigation/ScreenNav';
// Page Layout
import Page from '../Layout/Page';
// Helpers
import { checkLanguage, checkAuth } from '../../helpers/Functions';
// Config
import config from '../../config/Config';
// Style
import './Auth.scss';
import 'react-toastify/dist/ReactToastify.min.css';
// Globals
let LANG;
// Variables
let user;
const toastOptions = {
    autoClose: 6000,
    type: toast.TYPE.ERROR,
    position: toast.POSITION.BOTTOM_CENTER,
    draggablePercent: 60
};

class Register extends Component {
    constructor() {
        super()
        this.state = {
            name: '',
            parent_name: '',
            password: '',
            c_password: '',
            email: '',
            phone: '',
            gdpr: false,
            notification: '',
            submitEnabled: true,
            passwordSecure: false,
            passwordEmpty: true,
            passwordMEmpty: true,
            passwordsMatch: false,
            nothingEmpty: false
        }

        // bind functions to buttons and input fields
        this.onChange = this.onChange.bind(this)
        this.onSubmit = this.onSubmit.bind(this)
        // get copy in chosen language
        LANG = checkLanguage();
        // get auth user
        user = checkAuth();
    }

    onChange(e) {
        if(e.target.type === 'checkbox') {
            this.setState({ 
                [e.target.name]: e.target.checked 
            })
        } else {
            this.setState({ 
                [e.target.name]: e.target.value 
            })
        }
    }

    checkSubmit(){
        if (!this.state.passwordSecure || !this.state.passwordsMatch){
            this.setState({
                submitEnabled: false
            })
        }
        else if (this.state.passwordSecure && this.state.passwordsMatch){
            this.setState({
                submitEnabled: true
            })
        }
    }

    passwordsMatchCheck(e){
        this.setState({
            passwordMEmpty: false
        })
        if (e.target.value === this.state.password){
            this.setState({
                passwordsMatch: true
            })
            if (this.state.passwordSecure){
                this.setState({
                    submitEnabled: true
                })
            }
        }
        else {
            this.setState({
                passwordsMatch: false,
                submitEnabled: false
            })
        }
    }

    passwordSecureCheck(e){
        this.setState({
            passwordEmpty: false
        })
        let pass = e.target.value;
        if (pass.length >= 8){
            if (pass.toUpperCase() !== pass){
                if (pass.toLowerCase() !== pass){
                    if (/\d/.test(pass)){
                        this.setState({
                            passwordSecure: true
                        })
                        if (this.state.passwordsMatch){
                            this.setState({
                                submitEnabled: true
                            })
                        }
                    }
                    else {
                        this.setState({
                            passwordSecure: false,
                            submitEnabled: false
                        })
                    }
                }
                else {
                    this.setState({
                        passwordSecure: false
                    })
                    
                }
            }
            else {
                this.setState({
                    passwordSecure: false,
                    submitEnabled: false
                })
            }
        }
        else {
            this.setState({
                passwordSecure: false,
                submitEnabled: false
            })
        }
        //this.checkSubmit();
    }

    onSubmit(e) {
        // Prevent default
        e.preventDefault();
        // Get this to use later on this function
        let self = this;
        // API post request
        axios.post(config.server+'api/users/register', {
            name: this.state.name,
            parent_name: this.state.parent_name,
            password: this.state.password,
            c_password: this.state.c_password,
            email: this.state.email,
            phone: this.state.phone,
            gdpr: this.state.gdpr,
            type: 1,
            special: 0,
            active: 0
        })
        .then(function (response) {
            self.onResponse(true);
        })
        .catch(function (error) {
            if("errors" in error.response.data) {
                self.onResponse(false, Object.values(error.response.data.errors)[0], false);
            } else if("message" in error.response.data) {
                self.onResponse(false, Object.values(error.response.data.message)[0], false);
            } else {
                self.onResponse();
            }
        });
    }

    onResponse = (status, response, data) => {
        let s = status || false;
        let r = response || LANG.PASS_ERROR;
        //let d = data || false;
        if(s) {
            // API post request to send email
            axios.post(config.server+'api/users/register/confirmation', {
                email: this.state.email,
                secret: this.state.secret
            })
            // Push to confirmation screen
            this.props.history.push({pathname: '/register/confirmation', state: { success: true }});
        } else {
            // console.log(toast);
            let toastId = null
            toast.dismiss(this.toastId);
            toastId = toast(""+r, toastOptions)
        }
    }

    componentDidMount = () => {
        if(!LANG) {
            this.props.history.push({pathname: '/language', state: { second: true }});
        }
        if(user) {
            this.props.history.push({pathname: '/', state: { first: true }});
        }
    }

    render () {
        const lang = localStorage.getItem('lang');
        return (
            <Page className="">
                <ScreenNav 
                    title={LANG.REGISTER}
                    back={'/login'}
                />
                <div className="container">
                    <form className="form" noValidate onSubmit={this.onSubmit}>
                        <div className="input__field">
                            <i className="icon icon--type-user"></i>
                            <input 
                                className="input"
                                type="text"
                                name="name"
                                placeholder={LANG.USER_NAME_FIELD}
                                value={this.state.name}
                                onChange={this.onChange}
                            />
                        </div>
                        <div className="input__field">
                            <i className="icon icon--type-user"></i>
                            <input 
                                className="input"
                                type="text"
                                name="parent_name"
                                placeholder={LANG.USER_PARENT_NAME_FIELD}
                                value={this.state.parent_name}
                                onChange={this.onChange}
                            />
                        </div>
                        <div className="input__group">
                            <div className="input__field">
                                <i className="icon icon--type-password"></i>
                                <input 
                                    className="input"
                                    type="password"
                                    name="password"
                                    placeholder={LANG.PASSWORD_FIELD}
                                    value={this.state.password}
                                    onChange={(event) => {this.passwordSecureCheck(event); this.onChange(event);}}
                                />
                                <p>{!this.state.passwordSecure && !this.state.passwordEmpty ? LANG.PASSWORD_INSECURE : ''}</p>
                            </div>
                            <div className="input__field">
                                <i className="icon icon--type-password"></i>
                                <input 
                                    className="input"
                                    type="password"
                                    name="c_password"
                                    placeholder={LANG.PASSWORD_REPEAT_FIELD}
                                    value={this.state.c_password}
                                    onChange={(event) => {this.passwordsMatchCheck(event); this.onChange(event);}}
                                />
                                <p>{!this.state.passwordsMatch && !this.state.passwordMEmpty ? LANG.PASSWORDS_DONTMATCH : ''}</p>
                            </div>
                        </div>
                        <div className="input__group">
                            <div className="input__field">
                                <i className="icon icon--type-mail"></i>
                                <input 
                                    className="input"
                                    type="email"
                                    name="email"
                                    placeholder={LANG.EMAIL_FIELD}
                                    value={this.state.email}
                                    onChange={this.onChange}
                                />
                            </div>
                            <div className="input__field">
                                <i className="icon icon--type-phone"></i>
                                <input 
                                    className="input"
                                    type="text"
                                    name="phone"
                                    placeholder={LANG.PHONE_FIELD}
                                    value={this.state.phone}
                                    onChange={this.onChange}
                                />
                            </div>
                        </div>
                        
                        <div className="input__field input__field--checkbox">
                            <input 
                                className="checkbox"
                                id="gdpr"
                                type="checkbox"
                                name="gdpr"
                                checked={this.state.gdpr}
                                onChange={this.onChange}
                            /><label htmlFor="gdpr" className="primary-link">{ lang === 'fr' ? <a href="https://www.cliniclowns.be/app/privacy-fr.html">{LANG.GDPR_FIELD}</a> : <a href="https://www.cliniclowns.be/app/privacy-nl.html">{LANG.GDPR_FIELD}</a>}</label>  
                        </div>

                        <div className="app__btn__group">
                            {!this.state.submitEnabled ? 
                            <button 
                                disabled
                                className="app__btn app__btn--state-disabled"
                                type="submit" >
                                {LANG.REGISTER_BTN}
                            </button> : 
                            <button 
                                className="app__btn app__btn--state-normal"
                                type="submit" >
                                {LANG.REGISTER_BTN}
                            </button>
                            }
                        </div>
                        <div className="text--with-link">
                            <p>{LANG.LOGIN_QUESTION} </p>
                            <Link 
                                className=""
                                to={{ pathname: '/login', state: { middle: true } }}>
                                {LANG.LOGIN_BTN}
                            </Link>
                        </div>
                    </form>
                </div>
            </Page>
        )
    }
}

export default Register