// Core
import React, { Component } from 'react'
import Pusher from 'pusher-js'
// Components
import ScreenNav from '../Navigation/ScreenNav';
import MediaHandler from './MediaHandler';
import Peer from 'simple-peer';
// Page Layout
import Page from '../Layout/Page';
// Helpers
import { checkLanguage } from '../../helpers/Functions';
// Config
import config from '../../config/Config';
// Style
import './Videochat.scss'
import { StepPage } from 'twilio/lib/rest/studio/v1/flow/engagement/step';
// Globals
let LANG;



class Videochat extends Component {
    constructor(props) {
        super(props)
        this.state = {
            hasMedia: false,
            otherUserId: null,
            signalDone: false,
            ringing: false,
            waiting: false,
            hasError: false,
            connecting: false,
            connecting2: false,
            userVideos: [],
            playing: false,
            thirdPeer: false
        }
        // Refs
        this.btnAnswerRef = React.createRef();
        this.btnRejectRef = React.createRef();
        this.btnCallRef = React.createRef();
        // Get logged in user data
        this.user = JSON.parse(localStorage.getItem('login')) || JSON.parse(localStorage.getItem('loggedIn'));
        this.kidId = this.props.location.state !== undefined ? this.props.location.state.user : null;
        this.user.stream = null;
        // Peers variable
        this.peers = {};
        // Get camera and mic from user
        this.mediaHandler = new MediaHandler();
        // Connect user to presence pusher channels
        this.pusher = this.connectToPusher();
        this.setupPusher();
        this.connectToUserPresenceChannel();
        // Bind functions
        this.callTo = this.callTo.bind(this);
        this.onAnswer = this.onAnswer.bind(this);
        this.setupPusher = this.setupPusher.bind(this);
        this.startPeer = this.startPeer.bind(this);
        this.state.userVideos = [];
        this.streams = [];
        /** Twilio config response ICE servers */
        this.iceServers = null;

        // get copy in chosen language
        LANG = checkLanguage();
    }
    
    componentDidMount() {
        // const apiKey = 'SK422e88c30262d55bd1884bfea1d59756'; // You can generate this from www.twilio.com/console/runtime/api-keys/create
        // const apiSecret = 'gP7myG1inPfanfxXdcItFnF4dpc48SKf'; // You can generate this from www.twilio.com/console/runtime/api-keys/create
        // const client = require('twilio')(
        //     "AC4110a9e88fa472f07bcafae21e0664af",
        //     "ee526e8d2495d2a68f76151c63298954"
        //   );
        // client.tokens.create().then(token => {
        //     if (token && token.iceServers){
        //         console.log(token.iceServers);
        //         this.iceServers = token.iceServers;
        //     }
        //     else{
        //         console.error("No ice servers received!");
        //     }
        // });

        if(!LANG) {
            this.props.history.push({pathname: '/language', state: { second: true }});
        }

        const intervalId = setInterval(this.checkConnectingStet, 13000);
        this.setState({ intervalId });

        this.mediaHandler.getPermissions()
            .then((stream) => {
                this.setState({hasMedia: true});
                this.user.stream = stream;
                try {
                    this.myVideo.srcObject = stream;
                } catch (e) {
                    this.myVideo.src = URL.createObjectURL(stream);
                }
            })
        if(this.user.type === 1){
        console.log("youre waiting");
            this.setState({
                waiting: true
        });
        }

        this.myVideo.play();

        //Sounds
        this.audio = new Audio("/assets/sounds/dialing.mp3");
        this.audio.loop = true;
    }

    checkConnectingStet = () => {
        console.log("checking if youre still connecting");
        if (this.state.connecting){
            this.setState({
                hasError: true
            })
        }
    }

    timer = () => {
        if (this.state.count <= 0) {
          clearInterval(this.state.intervalId);
        } else {
          this.setState(prevState => ({
            count: prevState.count - 1
          }));
        }
      };

    componentDidCatch(error, info){
            // Display fallback UI
            console.log(error);
            this.setState({ hasError: true });
    }

    componentWillUnmount() {
        clearInterval(this.state.intervalId);
        this.closePeer(this.kidId);
        this.stopCam();
        if(this.user.type === 2) {
            console.log("unsubscribed to presence-video-channel-" + this.kidId);
            this.pusher.unsubscribe(`presence-video-channel-${this.kidId}`);
        }
        else {
            console.log("unsubscribed to presence-video-channel-" + this.user.id);
            this.pusher.unsubscribe(`presence-video-channel-${this.user.id}`);
        }
        // If clown
        if(this.user.type === 2) {
            // Push to agenda
            this.props.history.push({pathname: '/clown/agenda'});
        // if somethign else (kid)
        } else {
            // Push to home
            this.props.history.push({pathname: '/'});
        }
    }

    setupPusher = async () => {

        // Enable pusher logging - don't include this in production
        Pusher.logToConsole = false;
        this.pusher = await new Pusher(config.pusher_key, {
            authEndpoint: `${config.server}api/pusher/auth/${this.user.id}`,
            cluster: 'eu',
            forceTLS: true,
        });
        if(this.user.type === 2) {
            console.log("subscribed to presence-video-channel-" + this.kidId);
            this.channel = await this.pusher.subscribe(`presence-video-channel-${this.kidId}`);
        }
        else {
            console.log("subscribed to presence-video-channel-" + this.user.id);
            this.channel = await this.pusher.subscribe(`presence-video-channel-${this.user.id}`);
        }
        this.channel.bind('pusher:subscription_succeeded', () => {
            console.log("subscription succeeded: channel ");
            this.channel.bind(`client-signal-${this.user.id}`, (signal) => {
                console.log("incoming signal: ");
                console.log(signal);
                let peer = this.peers[signal.userId];
                // if peer doesnt already exist, weve got an incoming call
                console.log(signal.userId);
                console.log(this.state.userVideos);
                var existingFound = false;
                this.state.userVideos.forEach(video => {
                    if (video.videoId === 'video'+signal.userId)
                    {
                        console.log(signal.userId + " found");
                        existingFound = true;
                    }
                })
                if (!existingFound){
                    if(peer === undefined) {
                        this.setState({otherUserId: signal.userId});
                            peer = this.startPeer(signal.userId, false); 
                            var videoStream = {};
                            console.log(signal.userId);
                            videoStream.videoId = 'video'+signal.userId;
                            console.log("pushing videoStream 1");
                            if(this.state.userVideos.length === 0){
                                videoStream.setTop = 0;
                                videoStream.setLeft = 50;
                                videoStream.setWidth=100;
                                videoStream.setHeight = 100;
                            }
                            if(this.state.userVideos.length === 1){
                                this.state.userVideos[0].setLeft = 25;
                                this.state.userVideos[0].setTop = 0;
                                this.state.userVideos[0].setWidth = 50;
                                this.state.userVideos[0].setHeight = 50;

                                videoStream.setLeft = 75;
                                videoStream.setTop = 50;
                                videoStream.setWidth=50;
                                videoStream.setHeight=50;
                            }
                            if(this.state.userVideos.length === 2){
                                this.state.userVideos[0].setLeft = 25;
                                this.state.userVideos[0].setWidth = 33;
                                this.state.userVideos[0].setTop = 0;
                                this.state.userVideos[0].setHeight = 50;
                                this.state.userVideos[1].setLeft = 50;
                                this.state.userVideos[1].setWidth = 33;
                                this.state.userVideos[1].setTop = 33;
                                this.state.userVideos[1].setHeight = 50;
                                videoStream.setLeft = 75;
                                videoStream.setWidth = 33;
                                videoStream.setTop = 66;
                                videoStream.setHeight=50;
                            }
                            this.state.userVideos.push(videoStream);
                            console.log("starting peer because: ");
                            console.log('i was here');
                            console.log(this.peers);
                        this.btnRejectRef.current.style.display = 'block';
                        //this.setState(({ connecting }) => ({ connecting: !connecting }));
                        if(!this.state.connecting){
                            this.setState({connecting: true});
                        }
                        console.log("connecting state: " + this.state.connecting);
                        this.setState({
                            waiting:false
                        });
    
                        // var playPromise = this.userVideo.play();
    
                        // if (playPromise !== undefined){
                        //     playPromise.then(_ => {
                        //         console.log("playing");
                        //     })
                        //     .catch(error => {
                        //         console.error(error);
                        //     });
                        // }
                        this.state.userVideos.forEach(stream =>{
                            console.log(stream.videoId);
                            console.log(document.querySelector('videoContainer'));
                            //var playPromise = document.querySelector(stream.videoId).play();
                            var playPromise = stream.idRef.play();
    
                        if (playPromise !== undefined){
                            playPromise.then(_ => {
                                console.log("playing");
                                this.setState({playing: true});
                            })
                            .catch(error => {
                                console.error(error);
                            });
                        }
                        })
                    }
                }
                if (this.channel.members.count >= 2) {
                    this.channel.trigger('client-connection-' + signal.userId, {
                        user: this.user.id,
                    });
                }
                //if (this.state.signalDone === false){
                    if(peer){
                        console.log(peer);
                        setTimeout(() => {
                            peer.signal(signal.data);
                            this.state.signalDone = true;
                        }, 1000)
                    }

                //}
            });
            this.channel.bind(`client-connection-` + this.user.id, (data) => {
                if(this.user.type === 2) {
                    // Fake async (bug fix on fast call answer)
                    setTimeout(() => {
                        console.log(data.user);
                        var existingFound = false;
                        this.state.userVideos.forEach(video => {
                            if (video.videoId === 'video'+data.user)
                            {
                                console.log(data.user + " found");
                                existingFound = true;
                            }
                        })
                        if(!existingFound){
                            this.peers[data.user] = this.startPeer(data.user, true);
                            var videoStream = {};
                            videoStream.videoId = 'video'+data.user;
                            console.log("pushing videoStream 2");
                            if(this.state.userVideos.length === 0){
                                videoStream.setTop = 0;
                                videoStream.setLeft = 50;
                                videoStream.setWidth=100;
                                videoStream.setHeight = 100;
                            }
                            if(this.state.userVideos.length === 1){
                                this.state.userVideos[0].setLeft = 25;
                                this.state.userVideos[0].setTop = 0;
                                this.state.userVideos[0].setWidth = 50;
                                this.state.userVideos[0].setHeight = 50;

                                videoStream.setLeft = 75;
                                videoStream.setTop = 50;
                                videoStream.setWidth=50;
                                videoStream.setHeight=50;
                            }
                            if(this.state.userVideos.length === 2){
                                this.state.userVideos[0].setLeft = 25;
                                this.state.userVideos[0].setWidth = 33;
                                this.state.userVideos[0].setTop = 0;
                                this.state.userVideos[0].setHeight = 50;
                                this.state.userVideos[1].setLeft = 50;
                                this.state.userVideos[1].setWidth = 33;
                                this.state.userVideos[1].setTop = 50;
                                this.state.userVideos[1].setHeight = 50;
                                videoStream.setLeft = 75;
                                videoStream.setWidth = 33;
                                videoStream.setTop = 50;
                                videoStream.setHeight=50;
                            }
                            this.state.userVideos.push(videoStream);
                            console.log("starting peer in clientconnection: ");
                            console.log(this.peers);
                        }
                    }, 1000)
                }
            })
            this.channel.bind(`client-answer`, (data) => {
                console.log(data);
                    // var playPromise = this.userVideo.play();
                    // if (playPromise !== undefined){
                    //     playPromise.then(_ => {
                    //         console.log("connection: false");
                    //         this.setState({
                    //             connecting:false
                    //         })
                    //     })
                    //     .catch(error => {
                    //         console.error(error);
                    //     });
                    // }
                    this.state.userVideos.forEach(stream =>{
                        var playPromise = document.querySelector(stream.videoId).play();

                    if (playPromise !== undefined){
                        playPromise.then(_ => {
                            console.log("playing");
                            this.setState({playing: true});
                        })
                        .catch(error => {
                            console.error(error);
                        });
                    }
                    })
            });
            this.channel.bind(`client-hangup`, () => {
                this.onHangUp(this.kidId)
            });
            this.channel.bind(`client-connection`, (data) => {
                console.log("client connection");
                console.log(data);
                if(this.user.type === 2) {
                    // Fake async (bug fix on fast call answer)
                    setTimeout(() => {
                        console.log(data.user);
                        this.peers[data.user] = this.startPeer(data.user, true);
                        var videoStream = {};
                        videoStream.videoId = 'video'+data.user;
                        console.log("pushing videoStream 3");
                        if(this.state.userVideos.length === 0){
                            videoStream.setTop = 0;
                            videoStream.setLeft = 50;
                            videoStream.setWidth=100;
                            videoStream.setHeight = 100;
                        }
                        if(this.state.userVideos.length === 1){
                            this.state.userVideos[0].setLeft = 25;
                            this.state.userVideos[0].setTop = 0;
                            this.state.userVideos[0].setWidth = 50;
                            this.state.userVideos[0].setHeight = 50;

                            videoStream.setLeft = 75;
                            videoStream.setTop = 50;
                            videoStream.setWidth=50;
                            videoStream.setHeight=50;
                        }
                        if(this.state.userVideos.length === 2){
                            this.state.userVideos[0].setLeft = 25;
                            this.state.userVideos[0].setWidth = 33;
                            this.state.userVideos[0].setTop = 0;
                            this.state.userVideos[0].setHeight = 50;
                            this.state.userVideos[1].setLeft = 50;
                            this.state.userVideos[1].setWidth = 33;
                            this.state.userVideos[1].setTop = 50;
                            this.state.userVideos[1].setHeight = 50;
                            videoStream.setLeft = 75;
                            videoStream.setWidth = 33;
                            videoStream.setTop = 50;
                            videoStream.setHeight=50;
                        }
                        this.state.userVideos.push(videoStream);
                        console.log("starting peer in clientconnection: ");
                        console.log(this.peers);
                        this.forceUpdate();
                        setTimeout(() => {this.state.userVideos.forEach(stream =>{
                            console.log(stream.videoId);
                            console.log("hey1");
                            var playPromise = stream.idRef.play();
                            console.log("hey3");
                            if (playPromise !== undefined){
                                console.log("hey2");
                                playPromise.then(_ => {
                                    console.log("playing");
                                    this.setState({playing: true});
                                })
                                .catch(error => {
                                    console.error(error);
                                });
                            }
                        })}, 5000)
                    }, 1000)
                }
                this.btnRejectRef.current.style.display = 'block';
                this.audio.pause();
                this.btnCallRef.current.style.display = 'none';
                this.btnAnswerRef.current.style.display = 'none';
                //this.userVideo.play();
                console.log("hey");
                this.state.userVideos.forEach(stream =>{
                    console.log("hey1");
                    var playPromise = stream.idRef.play();
                    console.log("hey3");
                    if (playPromise !== undefined){
                        console.log("hey2");
                        playPromise.then(_ => {
                            console.log("playing");
                            this.setState({playing: true});
                        })
                        .catch(error => {
                            console.error(error);
                        });
                    }
                })
                this.channel.bind(`client-clown-connection`, (data) => {
                    console.log(data.user);
                //     this.setState({otherUserId: data.user});
                //     let peer = this.peers[data.user];
                //     peer = this.startPeer(data.user, false); 
                //     var videoStream = {};
                //     console.log(data.user);
                //     videoStream.videoId = 'video'+data.user;
                //     console.log("pushing videoStream 5");
                //     if(this.state.userVideos.length === 0){
                //         videoStream.setLeft = 50;
                //     }
                //     if(this.state.userVideos.length === 1){
                //         this.state.userVideos[0].setLeft = 25;
                //         videoStream.setLeft = 75;
                //     }
                //     if(this.state.userVideos.length === 2){
                //         this.state.userVideos[0].setLeft = 25;
                //         this.state.userVideos[1].setLeft = 50;
                //         videoStream.setLeft = 75;
                //     }
                //     this.state.userVideos.push(videoStream);
                //     console.log("starting peer because: ");
                //     console.log('i was here');
                //     console.log(this.peers);
                // this.btnRejectRef.current.style.display = 'block';
                // //this.setState(({ connecting }) => ({ connecting: !connecting }));
                // if(!this.state.connecting){
                //     this.setState({connecting: true});
                // }
                // console.log("connecting state: " + this.state.connecting);
                // this.setState({
                //     waiting:false
                // });

                // // var playPromise = this.userVideo.play();

                // // if (playPromise !== undefined){
                // //     playPromise.then(_ => {
                // //         console.log("playing");
                // //     })
                // //     .catch(error => {
                // //         console.error(error);
                // //     });
                // // }
                // this.state.userVideos.forEach(stream =>{
                //     console.log(stream.videoId);
                //     console.log(document.querySelector('videoContainer'));
                //     //var playPromise = document.querySelector(stream.videoId).play();
                //     var playPromise = stream.idRef.play();

                // if (playPromise !== undefined){
                //     playPromise.then(_ => {
                //         console.log("playing");
                //         this.setState({playing: true});
                //     })
                //     .catch(error => {
                //         console.error(error);
                //     });
                // }
                // })
                });
            });
            // this.makeConnectionFromNotification();
            console.log("i got here in the trigger");
            if (this.props.location.state !== undefined) {
                console.log("i got here in the trigger 2");
                if(this.user.type === 1 && this.props.location.state.videochat !== undefined) {
                    console.log("triggering client connection");
                    this.channel.trigger('client-connection', {
                        user: this.user.id,
                    });
                }
            }
        });
        // this.connectClients();
    }

    startPeer(userId, initiatorz) {
        console.log("creating peer " + userId);
        var OfferOptions = {};
        var AnswerOptions = {};
        if(this.user.type === 2){
            //initiatorz = true;
            OfferOptions= { 
                offerToReceiveAudio: true, 
                offerToReceiveVideo: true 
              };
        }
        else {
            //initiatorz = false;
            AnswerOptions= { 
                offerToReceiveAudio: false, 
                offerToReceiveVideo: false
              };
        }
        console.log("start peer with initiatorz " + initiatorz);
        console.log(OfferOptions);
        console.log(AnswerOptions);
        const peer = new Peer({
            initiator: initiatorz,
            offerOptions: OfferOptions,
            answerOptions: AnswerOptions,
            //config: { iceServers: [{ urls: 'stun:stun.l.google.com:19302' }, { urls: 'stun:global.stun.twilio.com:3478?transport=tcp' }] },
            reconnectTimer: 500,
            config: {
                iceServers:
                [
                    {
                      urls: 'turn:turn.clownscam.be:80?transport=tcp',
                      username: "cliniclown",
                      credential: "megaman4"
                    }
                ]
            },
            //stream: this.user.stream,
            //ONLY TIME IT WORKED IS WITH BELOW COMMENTED
            iceTransportPolicy: 'relay',
            trickle: false,
        });

        peer.addStream(this.user.stream);

        peer.on('signal', (data) => {
            console.log("signal data: ");
            console.log(data);
            console.log("triggering client signal + " + userId)
            console.log(this.peers);
            console.log(this.user.stream);
            this.channel.trigger(`client-signal-${userId}`, {
                type: 'signal',
                userId: this.user.id,
                data: data
            });
        });

        
        peer.on('connect', () => {
            peer.send(this.user.stream)
          })
          
        peer.on('data', data => {
            console.log("got " + data);
            console.log("from " + userId)
            console.log(data);
            // if(!this.state.playing){
            //     try {
            //         document.getElementById('video'+userId).srcObject = data;
            //     } catch (e) {
            //         console.log(e);
            //         //document.getElementById('video'+userId).src = URL.createObjectURL(data);
            //         var binaryData = [];
            //         binaryData.push(data);
            //         document.getElementById('video'+userId).src = URL.createObjectURL(new Blob(binaryData, {type: "application/zip"}))
            //     }
            // }
          })
          

        peer.on('stream', (stream) => {
        console.log("got stream from " + userId);
        if(document.getElementById('video'+userId) != null){
            try {
                console.log(stream);
                console.log(this.state.userVideos);
                console.log('im here now');
                console.log(document.getElementById('video'+userId));
                document.getElementById('video'+userId).srcObject = stream;
            } catch (e) {
                console.log(e);
                document.getElementById('video'+userId).src = URL.createObjectURL(stream);
            }
        }
        });

        peer.on('close', () => {
            let peer = this.peers[userId];
            if(peer !== undefined) {
                peer.destroy();
            }

            this.peers[userId] = undefined;
        });

        return peer;
    }

    callTo(userId) {
        if(userId) {
            this.setState({ringing: true});
            if (this.channel.members.count >= 2) {
                if(this.user.type === 2) {
                    // Fake async (bug fix on fast call answer)
                    setTimeout(() => {
                        this.peers[userId] = this.startPeer(userId, true);
                        var videoStream = {};
                        videoStream.videoId = 'video'+userId;
                        console.log("pushing videoStream 4");
                        if(this.state.userVideos.length === 0){
                            videoStream.setTop = 0;
                            videoStream.setLeft = 50;
                            videoStream.setWidth=100;
                            videoStream.setHeight = 100;
                        }
                        if(this.state.userVideos.length === 1){
                            this.state.userVideos[0].setLeft = 25;
                            this.state.userVideos[0].setTop = 0;
                            this.state.userVideos[0].setWidth = 50;
                            this.state.userVideos[0].setHeight = 50;

                            videoStream.setLeft = 75;
                            videoStream.setTop = 50;
                            videoStream.setWidth=50;
                            videoStream.setHeight=50;
                        }
                        if(this.state.userVideos.length === 2){
                            this.state.userVideos[0].setLeft = 25;
                            this.state.userVideos[0].setWidth = 33;
                            this.state.userVideos[0].setTop = 0;
                            this.state.userVideos[0].setHeight = 50;
                            this.state.userVideos[1].setLeft = 50;
                            this.state.userVideos[1].setWidth = 33;
                            this.state.userVideos[1].setTop = 50;
                            this.state.userVideos[1].setHeight = 50;
                            videoStream.setLeft = 75;
                            videoStream.setWidth = 33;
                            videoStream.setTop = 50;
                            videoStream.setHeight=50;
                        }
                        this.state.userVideos.push(videoStream);
                        console.log(this.peers);
                        this.forceUpdate();
                        setTimeout(() => {this.state.userVideos.forEach(stream =>{
                            console.log(stream.videoId);
                            console.log("hey1");
                            var playPromise = stream.idRef.play();
                            console.log("hey3");
                            if (playPromise !== undefined){
                                console.log("hey2");
                                playPromise.then(_ => {
                                    console.log("playing");
                                    this.setState({playing: true});
                                })
                                .catch(error => {
                                    console.error(error);
                                });
                            }
                        })}, 5000);
                        var [rommel1, rommel2, rommel3, kidUserId] = this.channel.name.split('-');
                        console.log(kidUserId);
                        var thisUserId = this.user.id;
                        this.channel.members.each(function (member) {
                            if (member.id !== kidUserId && thisUserId !== member.id){
                                var existingFound = false;
                                this.state.userVideos.forEach(video => {
                                    console.log(video.videoId);
                                    console.log(member.id);
                                    if (video.videoId === 'video'+member.id)
                                    {
                                        console.log(member.id + " found");
                                        existingFound = true;
                                    }
                                })
                                if (!existingFound){
                                    console.log("calling " + member.id);
                                    this.callTo(member.id);
                                }
                            }
                          }.bind(this));
                        this.channel.trigger('client-clown-connection', {
                            user: this.user.id,
                        });
                    }, 1000)
                }

                this.btnRejectRef.current.style.display = 'block';
                setTimeout(() => {
                    this.audio.pause();
                }, 500)
                this.btnCallRef.current.style.display = 'none';
                this.btnAnswerRef.current.style.display = 'none';
                // this.userVideo.play();
                this.state.userVideos.forEach(stream =>{
                    var playPromise = stream.idRef.play();

                    if (playPromise !== undefined){
                        playPromise.then(_ => {
                            console.log("playing");
                            this.setState({playing: true});
                        })
                        .catch(error => {
                            console.error(error);
                        });
                    }
                })
            } else {
                this.userChannel.trigger('client-call', {
                    nickname: this.user.nickname,
                    videochat: true
                });
            }
        } else {
            alert('Geen gebruiker geselecteerd om te bellen!');
        }
    }

    startRing() {
        this.audio.play();
    }

    stopRing() {
        //this.audio.pause();
    }

    onAnswer() {
        this.setState({hasMedia: false});
        this.channel.trigger(`client-answer`, {
            success: true,
            userId: this.user.id,
        });
    }

    connectToPusher = () => {
        // ! Enable pusher logging - don't include this in production
        Pusher.logToConsole = true;
        // Puser credentials
        let pusher = new Pusher(config.pusher_key, {
            cluster: 'eu',
            authEndpoint: `${config.server}api/pusher/auth/${this.user.id}`,
            forceTLS: true,
        });
        return pusher;
    }

    connectToUserPresenceChannel = () => {
        if(this.user.type === 2) {
            // Connect to the user presence channel
            this.userChannel = this.pusher.subscribe(`presence-user-${this.kidId}`);
            this.userChannel.bind(`client-reject`, (data) => {
                console.log(this.peers);
            });
        }
    }

    onHangUp = (userId) => {
        this.channel.trigger(`client-hangup`, {
            success: true,
            userId: this.user.id,
        });
        this.closePeer(userId);
        this.stopCam();
    }

    closePeer = (userId) => {
        // Remove peer
        let peer = this.peers[userId];
            if(peer !== undefined) {
                console.log("destroying peer " + peer);
                peer.destroy();
            }
        this.peers[userId] = undefined;
    }

    stopCam = () => {
        // Stop webcam
        this.audio.pause();
        let tracks = this.user.stream.getTracks();
        tracks.forEach(function(track) {
            track.stop();
        });
        // this.userVideo.srcObject = null;
        // this.userVideo.src = null;
        this.state.userVideos.forEach(stream =>{
            stream.srcObject = null;
            stream.src = null;
        })
        if(this.user.type === 1) {
            this.props.history.push({pathname: '/', state: { first: true }});
        }
        else {
            this.props.history.push({pathname: '/clown/agenda', state: { first: true }});
        }
    }

    render () {
        const image = (
            this.props.imageSrc ? <img className="back__image" src={this.props.imageSrc} alt="back to home"/> : ""
        );
        var streamsPC = this.state.userVideos.map((stream, index) => <div class="column" style={{width:100/this.state.userVideos.length+'%'}}><video style={{opacity:'1', height:'100%', left: stream.setLeft+'%', width: stream.setWidth + '%', top: 0}} className="user-video" id={stream.videoId} ref={(ref) => {stream.idRef = ref;}} playsInline></video></div>
        );
        var streamsMobile = this.state.userVideos.map((stream, index) => <div class="row" style={{width:'100%', height: '100%'}}><video style={{opacity:'1', height: stream.setHeight + '%', left: '50%', top: stream.setTop+'%', width: '100%'}} className="user-video" id={stream.videoId} ref={(ref) => {stream.idRef = ref;}} playsInline></video></div>
        );
        console.log(streamsPC);
        console.log(this.peers.length);
        const vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);
        const vh = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0);
        console.log(vw + ' ' + vh);
        return (
            <Page className="second">
            <ScreenNav 
                    back={'/'}
                    border={false}
            />
            <div className="container">
            {this.state.waiting ? <div className="videochat__controls reservation__reminder">{LANG.WELCOME}</div> : ''}
            {/* {this.state.hasError ? <div className="videochat__controls reservation__reminder error__reminder">{LANG.TOO_LATE}<br/> <button className="app__btn app__btn--state-normal error__btn" onClick={() => this.onHangUp(this.kidId)} >OK!</button></div> : ''} */}
            <div className="videochat">
                <div className="videochat__controls">
                    {this.user.type === 2 ? <a href="#" ref={this.btnCallRef} className={"videochat__btn videochat__call " + this.kidId} onClick={() => {this.callTo(this.kidId); this.startRing()}}><img alt="call icon" src="/assets/illustrations/call-answer.svg" /></a> : ''}
                    <a href="#" ref={this.btnAnswerRef} className="videochat__btn videochat__answer" onClick={() => this.onAnswer()}><img alt="answer icon" src="/assets/illustrations/call-answer.svg" /></a>
                    <a href="#" ref={this.btnRejectRef} className="videochat__btn videochat__reject" onClick={() => this.onHangUp(this.kidId)} ><img alt="answer icon" src="/assets/illustrations/call-deny.svg" /></a>
                </div>
                <div id="videoContainer" className="video-container">
                    <video className="my-video" ref={(ref) => {this.myVideo = ref;}} playsInline muted></video>
                    {vh > vw ? <div>{streamsMobile}</div> : <div class="row">{streamsPC}</div>}
                    {/* <video className="user-video" ref={(ref) => {this.userVideo2 = ref;}} playsInline></video> */}
                </div>
            </div>
            </div>
            </Page>
        )
    }
}

export default Videochat