import React, { Component } from 'react'
import { Link, withRouter } from 'react-router-dom'
import './ScreenNav.scss'

class ScreenNav extends Component {
    constructor(props) {
        super(props)
        if(props.location.state && props.location.state.second) {
            this.state = {
                locationState: { first: true }
            }
        } else {
            this.state = {
                locationState: { middle: true }
            }
        }
    }

    toLowerLevel = () => {
        document.querySelector('.page').style.transform = "translateX(100%)";
    }

    render() {
        const image = (
            this.props.imageSrc ? <img className="back__image" src={this.props.imageSrc} alt="back to home image"/> : ""
        );
        return (
            <nav className={this.props.border ? 'screen__nav' : 'screen__nav_noborder'}>
                <div className="container">
                    <ul className="screen__nav--list">
                        <li>
                            <Link 
                                // onClick={() => this.toLowerLevel()}
                                to={{ pathname: this.props.back, state: this.state.locationState }}>
                                <div className="screen__nav-icon" alt="back button"></div>
                            </Link>
                            {image}
                        </li>
                        <li><h1 className="screen__nav-title">{this.props.title}</h1></li>
                    </ul>
                </div>
            </nav>
        )
    }
}

// Set default props
ScreenNav.defaultProps = {
    imageSrc: false,
    border: true,
};

export default withRouter(ScreenNav)