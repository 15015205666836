// Core
import React, { Component } from 'react'
import { Link, NavLink } from 'react-router-dom'

class Footer extends Component {
    render () {
        return (
            <footer className="footer">
                <div className="d-sm-flex justify-content-center justify-content-sm-between">
                <span className="text-muted text-center text-sm-left d-block d-sm-inline-block">Copyright © {new Date().getFullYear()} <a href="https://www.cliniclowns.be/" target="_blank">Cliniclowns</a>. All rights reserved.</span>
                <span className="float-none float-sm-right d-block mt-1 mt-sm-0 text-center">Hand-crafted & made with <i className="mdi mdi-heart text-danger"></i></span>
                </div>
            </footer>
        )
    }
}

export default Footer