import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { withRouter } from "react-router-dom";
// Style
import './Page.scss';

function Page({ children, background, location: { state }, className }) {
  // console.log(state);
  const cx = classNames({
    page: true,
    mainBg: true,
    "first": state && state.first,
    "second": state && state.second,
    "third": state && state.third,
    "middle": state && state.middle,
  });
  
  return (
    <section className={className+' '+cx} >
      {children}
    </section>
  );
}

Page.propTypes = {
  children: PropTypes.node.isRequired,
  color: PropTypes.string,
  background: PropTypes.string,
  className: PropTypes.string
};

Page.defaultProps = {
  background: "#BBF0E8"
};

export default withRouter(Page);
