// Core
import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'
import { toast } from 'react-toastify';
import axios from 'axios'
// Components
import ScreenNav from '../../Navigation/ScreenNav';
// Style
// Page Layout
import Page from '../../Layout/Page';
// import './Login.scss'
// Config
import config from '../../../config/Config';
// Globals
const toastOptions = {
    autoClose: 6000,
    type: toast.TYPE.ERROR,
    position: toast.POSITION.BOTTOM_CENTER,
    draggablePercent: 60
};

class Login extends Component {
    constructor(props) {
        super(props)
        this.state = {
            clown: {
                loggedIn: false,
                data: {

                }
            },
            email: '',
            password: '',
            notification: ''
        }

        // bind functions to buttons and input fields
        this.onChange = this.onChange.bind(this)
        this.onSubmit = this.onSubmit.bind(this)
    }

    onChange(e) {
        this.setState({ 
            [e.target.name]: e.target.value 
        })
    }

    onSubmit(e) {
        // Prevent default
        e.preventDefault();
        // Get this to use later on this function
        let self = this;
        // API post request
        axios.post(config.server+'api/clowns/login', {
            email: this.state.email,
            password: this.state.password
        })
        .then(function (response) {
            self.onResponse(true, response.data.message, response.data.data);
        })
        .catch(function (error) {
            if("errors" in error.response.data) {
                self.onResponse(false, Object.values(error.response.data.errors)[0], false);
            } else if("message" in error.response.data) {
                self.onResponse(false, Object.values(error.response.data.message)[0], false);
            } else {
                self.onResponse();
            }
        });
    }

    onResponse = (status, response, data) => {
        if(status) {
            localStorage.setItem('loggedIn', JSON.stringify(data));
            this.props.history.push('/clown/agenda');
        } else {
            // console.log(toast);
            let toastId = null
            toast.dismiss(this.toastId);
            this.toastId = toast(""+response, toastOptions)
        }
    }

    componentDidMount = () => {

    }
    
    render () {
        return !this.state.clown.loggedIn ? (
            <Page className="second">
                <ScreenNav 
                    title={'Clown login'}
                    back={'/'}
                />
                <div className="container">
                    <form className="form" noValidate onSubmit={this.onSubmit}>
                        <div className="input__field">
                                <i className="icon icon--type-user"></i>
                                <input 
                                    className="input"
                                    type="email"
                                    name="email"
                                    placeholder="Email"
                                    value={this.state.email}
                                    onChange={this.onChange}
                                />
                        </div>
                        <div className="input__field">
                                <i className="icon icon--type-password"></i>
                                <input 
                                    className="input"
                                    type="password"
                                    name="password"
                                    placeholder="Password"
                                    value={this.state.password}
                                    onChange={this.onChange}
                                />
                        </div>
                        <div className="btn__group">
                                <button 
                                    className="app__btn app__btn--state-normal"
                                    type="submit" >
                                    Login
                                </button>
                            </div>
                    </form>
                </div>
            </Page>

        ) : <Redirect to="/clown/agenda" />
    }
}

export default Login