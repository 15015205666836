import React, { Component } from 'react'
import { Link } from 'react-router-dom'
// Page Layout
import Page from '../Layout/Page';
// Style
import './Language.scss';

class Language extends Component {
    constructor() {
        super()
        this.state = {
            
        }
    }

    handleClick = (lang) => {
        localStorage.setItem('lang', lang);
    }

    render () {
        return (
            <Page className="second">
                <div className="container language">
                    <div className="lang__wrapper">
                        <Link 
                            to={{ pathname: '/', state: { first: true } }} 
                            onClick={() => this.handleClick("fr")}
                            className="link link-frans">
                            <img src="/assets/lang/fr.svg" alt="frans language"/> 
                        </Link>
                        <Link 
                            to={{ pathname: '/', state: { first: true } }} 
                            onClick={() => this.handleClick("nl")}
                            className="link link-dutch">
                            <img src="/assets/lang/nl.svg" alt="dutch language"/> 
                        </Link>
                        <div className="stick is-centered">
                            <img src="/assets/lang/stick.svg" alt="language stick"/>
                        </div>
                        <div className="stick is-centered">
                            <img src="/assets/lang/logo.svg" alt="logo"/>
                        </div>
                    </div>
                </div>
            </Page>
        )
    }
}

export default Language