// Core
import React, { Component } from 'react'
import { NavLink } from 'react-router-dom'
// Helpers
import { checkLanguage } from '../../../../helpers/Functions';
// Style
// import '../ClownAdmin.scss';
// Globals
let LANG;

class SideBar extends Component {

    constructor(props) {
        super(props)
        // Static variables
        LANG = checkLanguage();
    }

    render () {
        return (
            <nav className="sidebar sidebar-offcanvas" id="sidebar">
                <ul className="nav">
                    <li className="nav-item nav-profile">
                        <a href="" className="nav-link">
                            <div className="nav-profile-image">
                                <img src={this.props.avatar} alt="profile"/>           
                            </div>
                            <div className="nav-profile-text d-flex flex-column">
                                <span className="font-weight-bold mb-2">{this.props.name}</span>
                                <span className="text-secondary text-small">Clown</span>
                            </div>
                        </a>
                    </li>
                    <li className="nav-item">
                        <NavLink
                            to="/clown/agenda"
                            className="nav-link"
                            activeClassName="active" >
                            <span className="menu-title">{LANG.CLOWN_CAL}</span>
                            <i className="mdi mdi-calendar menu-icon"></i>
                        </NavLink>
                    </li>
                    <li className="nav-item">
                        <NavLink
                            to="/clown/guestbook"
                            className="nav-link"
                            activeClassName="active" >
                            <span className="menu-title">{LANG.CLOWN_GUESTBOOK}</span>
                            <i className="mdi mdi-book-open-page-variant menu-icon"></i>
                        </NavLink>
                    </li>
                </ul>
            </nav>
        )
    }
}

export default SideBar