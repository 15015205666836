// Core
import React, { Component } from 'react'
// Components
import ScreenNav from '../Navigation/ScreenNav';
// Page Layout
import Page from '../Layout/Page';
// Helpers
import { checkLanguage, getVideos } from '../../helpers/Functions';
// Styles
import './Videos.scss'
// Globals
let LANG;

class Videos extends Component {
    constructor() {
        super()
        this.state = {
            youTubeVideos: [],
            guideVideos: [],
            // youTubeVideosUrl: []
        }
        // get copy in chosen language
        LANG = checkLanguage();
    }

    componentDidMount() {
        if(!LANG) {
            this.props.history.push('/language');
        }
        getVideos().then(res => {
            if(res) {
                let videos = [];
                let guides = [];
                res.items.forEach(item => {
                    if(!item.snippet.title.includes('Curry') && !item.snippet.title.includes('Bizou') && !item.snippet.title.includes('Reste dans ta kot')) {
                        if(localStorage.getItem('lang') === 'fr') {
                            if(item.snippet.title.includes('Français')) {
                                if (item.snippet.title.includes('Clinicam')){
                                    guides.push(item);
                                }
                                else{
                                    videos.push(item);
                                }
                            }
                        } else {
                            if(item.snippet.title.includes('Nederlands')) {
                                if (item.snippet.title.includes('Clinicam')){
                                    guides.push(item);
                                }
                                else{
                                    videos.push(item);
                                }
                            }
                        }
                    }
                });
                this.setState({
                    youTubeVideos: videos,
                    guideVideos: guides,
                });
            }
            // let videoUrls = [];
            // this.state.youTubeVideos.forEach(video => {
            //     if(video.id.videoId) {
            //         let url = "https://www.youtube.com/embed/" + video.id.videoId;
            //         videoUrls.push(url)
            //     }
            // });
            // this.setState({
            //     youTubeVideosUrl: videoUrls
            // })
        });
    }

    render () {
        // console.log(this.state.youTubeVideos);
        let listVideos = null;
        if(this.state.youTubeVideos) {
            listVideos = this.state.youTubeVideos.map((video) =>
            <li className="video__item" key={Math.random()}>
                 <div className="video__item__wrapper">
                    <iframe width="auto" height="auto" src={"https://www.youtube.com/embed/" + video.id.videoId} frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                    {/* <button className="videoPoster js-videoPoster" style={ { backgroundImage: "url("+video.snippet.thumbnails.medium.url+")" } }></button> */}
                    <div className="video__snippet">
                        <p>{video.snippet.title}</p>
                    </div>
                </div>
            </li>
            );
        }
        let guideListVideos = null;
        if(this.state.guideVideos) {
            guideListVideos = this.state.guideVideos.map((video) =>
            <li className="video__item" key={Math.random()}>
                 <div className="video__item__wrapper">
                    <iframe width="auto" height="auto" src={"https://www.youtube.com/embed/" + video.id.videoId} frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                    {/* <button className="videoPoster js-videoPoster" style={ { backgroundImage: "url("+video.snippet.thumbnails.medium.url+")" } }></button> */}
                    <div className="video__snippet">
                        <p>{video.snippet.title}</p>
                    </div>
                </div>
            </li>
            );
        }
        return (
            <Page>
                <ScreenNav 
                    title={LANG.VIDEOS}
                    back={'/'}
                    imageSrc={'/assets/illustrations/tipi.svg'}
                />
                <div className="container videos__container">
                <div style={{textAlign: "center"}}><h1 style={{fontSize: 30, color: "#0C2B96"}}>{LANG.CLINICAM_VIDEOS}</h1><br/><br/></div>
                    <ul className="container--flex">
                        {guideListVideos}
                    </ul>
                </div>
                <div className="container videos__container">
                <div style={{textAlign: "center"}}><h1 style={{fontSize: 30, color: "#0C2B96"}}>{LANG.YOUTUBE_VIDEOS}</h1><br/><br/></div>
                    <ul className="container--flex">
                        {listVideos}
                    </ul>
                </div>
            </Page>
        )
    }
}

export default Videos