// Core
import React, { Component } from 'react'

class Header extends Component {
    constructor(props) {
        super(props);
        this.menu = React.createRef();
    }

    logout = () => {
        localStorage.removeItem('loggedIn');
        // this.props.history.push({pathname: '/clown/login'});
    }

    toggle = () => {
        document.getElementById('sidebar').classList.toggle("active");
    }

    dropdown = () => {
        document.querySelector('.navbar-dropdown').classList.toggle('show');
    }

    render () {
        return (
            <nav className="navbar default-layout-navbar col-lg-12 col-12 p-0 fixed-top d-flex flex-row">
                <div className="text-center navbar-brand-wrapper d-flex align-items-center justify-content-center">
                    <a className="navbar-brand brand-logo" href="index.html"><img src="/assets/logo/logo-word.svg" alt="logo"/></a>
                    <a className="navbar-brand brand-logo-mini" href="index.html"><img src="/assets/logo/logo-word.svg" alt="logo"/></a>
                </div>
                <div className="navbar-menu-wrapper d-flex align-items-stretch">
                    <div className="search-field d-none d-md-block">

                    </div>
                    <ul className="navbar-nav navbar-nav-right">
                        <li className="nav-item nav-profile dropdown">
                            <a onClick={() => this.dropdown()} className="nav-link dropdown-toggle" id="profileDropdown" href="#" data-toggle="dropdown" aria-expanded="false">
                            <div className="nav-profile-img">
                                <img src={this.props.avatar} alt="clown avatar"/>
                                <span className="availability-status online"></span>             
                            </div>
                            <div className="nav-profile-text">
                                <p className="mb-1 text-black">{this.props.name}</p>
                            </div>
                            </a>
                            <div className="dropdown-menu navbar-dropdown" aria-labelledby="profileDropdown">
                                <a onClick={() => this.logout()} className="dropdown-item" href="#">
                                    <i className="mdi mdi-logout mr-2 text-primary"></i>
                                    Signout
                                </a>
                            </div>
                        </li>
                    </ul>
                    <button onClick={() => this.toggle()} ref={this.menu} className="navbar-toggler navbar-toggler-right d-lg-none align-self-center" type="button" data-toggle="offcanvas">
                    <span className="mdi mdi-menu"></span>
                    </button>
                </div>
            </nav>
        )
    }
}

export default Header