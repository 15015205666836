// Core
import React, { Component } from 'react'
import { Link } from 'react-router-dom'
// Components
import ScreenNav from '../Navigation/ScreenNav';
// Page Layout
import Page from '../Layout/Page';
// Helpers
import { checkLanguage, checkAuth } from '../../helpers/Functions';
// Styles
import './Settings.scss'
// Globals
let LANG;
let user;

class Settings extends Component {
    constructor() {
        super()
        this.state = {
            loggedIn: localStorage.getItem('login') || false
        }

        // get copy in chosen language
        LANG = checkLanguage();
        user = checkAuth();
        // console.log(user);
    }

    componentDidMount() {
        console.log("V1.0.10");
        if(!LANG) {
            this.props.history.push('/language');
        }
    }

    logOut = () => {
        localStorage.removeItem('login');
        window.location.reload();
        //this.props.history.push({pathname: '/', state: { first: true, refresh: true }});
    }

    toClown = () => {
        this.props.history.push({pathname: '/clown/agenda'});
    }

    handleClick = (lang) => {
        localStorage.setItem('lang', lang);
    }

    render () {
        return (
            <Page className="">
                <ScreenNav 
                    title={LANG.SETTINGS}
                    back={'/'}
                />
                <div className="container settings">
                    {this.state.loggedIn ? <h1>{LANG.LOGGEDIN_AS} {user.name.charAt(0).toUpperCase() + user.name.slice(1)}. </h1> : <h1>{LANG.NOT_LOGGEDIN}</h1>}
                    {this.state.loggedIn ? <div className="app__btn__group">
                        <button 
                            className="app__btn app__btn--state-normal"
                            onClick={() => this.logOut()} >
                            {LANG.LOGOUT_BTN}
                        </button>
                    </div> : null}
                    {!this.state.loggedIn ? <div className="app__btn__group">
                        <button 
                            className="app__btn app__btn--state-normal"
                            onClick={() => this.props.history.push({pathname: '/login', state: { second: true }})} >
                            {LANG.LOGIN_BTN_SETTINGS}
                        </button>
                    </div> : null}
                    {!this.state.loggedIn ? <div className="app__btn__group">
                        <button 
                           className="app__btn app__btn--state-normal"
                            onClick={() => this.toClown()} >
                            {LANG.CLOWN_LINK}
                        </button>
                    </div> : null}
                </div>
                <div className="container language">
                <h1>{LANG.CHANGE_LANG}</h1>
                    <div className="lang__wrapper">
                        <Link 
                            to={{ pathname: '/', state: { first: true } }} 
                            onClick={() => this.handleClick("fr")}
                            className="link link-frans">
                            <img src="/assets/lang/fr.svg" alt="frans language"/> 
                        </Link>
                        <Link 
                            to={{ pathname: '/', state: { first: true } }} 
                            onClick={() => this.handleClick("nl")}
                            className="link link-dutch">
                            <img src="/assets/lang/nl.svg" alt="dutch language"/> 
                        </Link>
                    </div>
                </div>
            </Page>
        )
    }
}

export default Settings