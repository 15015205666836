// Core
import React, { Component } from 'react'
import moment from 'moment';
import localization from 'moment/locale/nl';
import { toast } from 'react-toastify';
import axios from 'axios'
import Slider from "react-slick";
// Components
import ScreenNav from '../Navigation/ScreenNav';
// Page Layout
import Page from '../Layout/Page';
// Helpers
import { checkLanguage, checkAuth, getVideoChatClowns, isObjEmpty, getUserReservation, getReservationsByClownAndDate, getAllReservationsByDate, destroyReservation, getClowns } from '../../helpers/Functions';
// Config
import config from '../../config/Config';
// Style
import './Agenda.scss'
import 'react-toastify/dist/ReactToastify.min.css';
// Globals
let LANG;
// Variables
let user;
const toastOptions = {
    autoClose: 6000,
    type: toast.TYPE.ERROR,
    position: toast.POSITION.BOTTOM_CENTER,
    draggablePercent: 60
};
const slickOptionsMobile = {
    dots: false,
    infinite: false,
    arrows: true,
    speed: 500,
    centerPadding: "30px",
    slidesToShow: 3,
    slidesToScroll: 3,
    rows:3
};
const slickOptions = {
    dots: false,
    infinite: false,
    arrows: true,
    speed: 500,
    centerPadding: "30px",
    slidesToShow: 3,
    slidesToScroll: 3,
    rows: 3
};

class Agenda extends Component {
    constructor(props) {
        super(props)
        this.state = {
            // reservation
            activeReservations: null,
            reservation: [],
            daysLeft: null,
            clownAvatar: null,
            clownAdminAvatar: null,
            clownNickname: null,
            reservationDate: null,
            sep_clown_id: "",
            clownsAmount: null,
            clowns: [],
            days: [],
            hours: [],
            // agenda data needed
            listClowns: [],
            listDates: [],
            listHours: [],
            // Input Values
            dateValue: "",
            hourValue: "",
            // Values to send via api
            user_email: "",
            clown_id: "",
            date: "",
            start_at: "",
            end_at: "",
            isMobile: "",
            // Clowns slider
            slider: null,
            gettingRes: false,
            selectedClown: null
        }

        // Static variables
        this.user = JSON.parse(localStorage.getItem('login')) || null;

        // bind functions to buttons and input fields
        this.onSubmit = this.onSubmit.bind(this)
        this.handleClownSelectChange = this.handleClownSelectChange.bind(this);
        this.handleDateSelectChange = this.handleDateSelectChange.bind(this);
        this.handleHourSelectChange = this.handleHourSelectChange.bind(this);

        // get copy in chosen language
        LANG = checkLanguage();
        // get auth user
        user = checkAuth();
    }

    onSubmit(e) {
        // Prevent default
        e.preventDefault();
        // Get this to use later on this function
        let self = this;
        // API post request
        let options = {
            method: 'POST',
            url: config.server+'api/users/reservation',
            data: {
                user_email: user.email,
                clown_id: this.state.clown_id,
                date: this.state.date,
                start_at: this.state.start_at,
                end_at: this.state.end_at
            },
            headers: {
                'Authorization': 'Bearer ' + user.token,
            },
            json: true
        };

        axios(options)
        .then(function (response) {
            self.onResponse(response.data.success, response.data.message);
        })
        .catch(function (error) {
            self.onResponse(error.response.data.success, Object.values(error.response.data.errors)[0], false);
        });
    }

    onResponse = (status, response) => {
        if(status) {
            // Push to confirmation screen
            this.props.history.push({pathname: '/agenda/confirmation', state: { success: true }});
        } else {
            let toastId = null
            toast.dismiss(this.toastId);
            this.toastId = toast(""+response, toastOptions)
        }
    }
    
    componentDidMount() {
        var ind_clown_id = this.props.location.state.sep_clown_id;
        this.setState({
            ind_clown_id: ind_clown_id
        });
        var isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
        this.setState({
            isMobile: isMobile
        });
        if(!LANG) {
            this.props.history.push({pathname: '/language', state: { second: true }});
        } else {
            if(!user) {
                this.props.history.push({pathname: '/login', state: { second: true }});
            } else {
                getClowns().then(res => {
                    this.setState({
                        clownsAmount: res.data.length
                    });
                });
                let hasReservation;
                getUserReservation(user.id).then(res => {
                    hasReservation = res.success;
                    if(res.success) {
                        let now  = moment().format('YYYY-MM-DD');
                        let duration = moment.duration(moment(res.data[0].date,"YYYY-MM-DD").diff(moment(now,"YYYY-MM-DD")));
                        let daysLeft = duration.asDays();
                        this.setState({
                            reservation: res.data,
                            clownAvatar: res.data[0].clown.avatarReservation,
                            clownNickname: res.data[0].clown.nickname,
                            daysLeft: daysLeft,
                            reservationDate: moment(res.data[0].date).format('dddd, D MMMM'),
                            start_at: moment(res.data[0].start_at, "HH:mm:ss").format('HH:mm'),
                            end_at: moment(res.data[0].end_at, "HH:mm:ss").format('HH:mm')
                        })
                    }
                }) 
                if(!hasReservation) {
                    // console.log("no reservations found");
                    getVideoChatClowns().then(res => {
                        if(this.state.ind_clown_id != undefined){
                            //console.log("sep clown id: " + this.state.ind_clown_id);
                            for (var i = 0; i < res.data.length; i++) {
                                if (res.data[i].id == this.state.ind_clown_id){
                                    this.setState({
                                        clowns: res.data[i]
                                    })
                                    //console.log("clowns state");
                                    //console.log(this.state.clowns);
                                }
                            }
                        }
                        else{
                            // console.log("no sep clown id found");
                            this.setState({
                                clowns: res.data,
                            })
                        }
                        // console.log()
                        this.buildClowns();
                        if (this.state.ind_clown_id != undefined){
                            this.handleClownDates(this.state.clowns.id);
                        }
                        this.setState({slider: <Slider {...slickOptions}>{this.state.listClowns}</Slider>});
                        
                    });
                }
            }
        }
    }

    buildClowns = () => {
        //console.log("clowns state");
        //console.log(this.state.clowns);
        if (this.state.clowns.length > 1){
        this.setState({
            listClowns: this.state.clowns.map((clown) =>
                <label className="reservation__clown__item" key={clown.id}>
                    <input
                        style={
                            {
                                //load green ring depending on if clown is selected or not
                                backgroundImage: this.state.clown_id == clown.id ? "url('/assets/images/clowns/green-ring-selected.png'), url(" + config.server + clown.clown.avatar_app + ")" : "url(" + config.server + clown.clown.avatar_app + ")"
                            }
                        }
                        type="radio"
                        name="clown"
                        className="reservation__clown"
                        value={clown.id}
                        onChange={this.handleClownSelectChange}
                        />
                </label>
            )
        })
        }
        else{
        this.setState({
            listClowns: 
                <label className="reservation__clown__item" key={this.state.clowns.id}>
                    <input
                        style={
                            {
                                //load green ring depending on if clown is selected or not
                                backgroundImage: "url('/assets/images/clowns/green-ring-selected.png'), url(" + config.server + this.state.clowns.clown.avatar_app + ")"
                            }
                        }
                        type="radio"
                        name="clown"
                        className="reservation__clown"
                        value={this.state.clowns.id}
                        />
                </label>
        })
        }
    }

    buildDates = async () => {
        let days = [];
        let week = 0;
        let totalWeeksToShow = 3;
        let today = moment().isoWeekday();

        for (let i = 0; i < totalWeeksToShow; i++) {
            for (let i2 = 0; i2 < this.state.days.length; i2++) {
                let day = parseInt(this.state.days[i2]);
                // if we haven't yet passed the day of the week that I need:
                if (today <= day) { 
                    // then just give me this week's instance of that day
                    days.push([
                        moment().isoWeekday(day).format('YYYY-MM-DD'),
                        moment().isoWeekday(day).format('dddd, D MMMM'),
                    ]);
                    today = day+1;
                // otherwise, give me *next week's* instance of that same day
                } else {
                    days.push([
                        moment().add(week, 'weeks').isoWeekday(day).format('YYYY-MM-DD'),
                        moment().add(week, 'weeks').isoWeekday(day).format('dddd, D MMMM')
                    ]);
                }
                if((i2+1) == this.state.days.length) {
                    week++;
                }
            }
            // console.log(week);
            // today = moment().isoWeekday();
        }
        this.setState({
            listDates: days.map((day) =>
                <option key={day[0]} value={day[0]}>{day[1]}</option>
            ),
        });
    }

    buildHours = async (date) => {
        let selected;
        if (this.state.clowns.length > 1)
        {
            selected = this.getSelectedClown(this.state.clown_id);
            this.setClownWorkingHours(selected);
        }
        else{
            selected = this.state.clowns.id;
            this.setClownWorkingHours(this.state.clowns);
        }

        // getReservations by Clown and date
        await getReservationsByClownAndDate(this.state.clown_id, date).then(res => {
            // console.log("got this reservation: ");
            // console.log(res.data);
            this.setState({
                activeReservations: res.data,
            })
        })

        // var reservations = [];
        // this.setState({
        //     gettingRes: true
        // })
        // for (var i = 8; i<this.state.clownsAmount+8; i++){
        //     await getReservationsByClownAndDate(i, date).then(res => {
        //         // console.log(this.state.gettingRes);
        //         if (res.data[0]){
        //             for (var j = 0; j<res.data.length; j++){
        //                 reservations.push(res.data[j]);
        //             }
        //         }
        //         this.setState({
        //              activeReservations: reservations,
        //         })
        //     })
        // }
        // this.setState({
        //     gettingRes: false
        // })

        // Get day of week number
        var date = moment(date);
        var dow = date.day();
        let hours = [];

        for (let i = 0; i < this.state.hours.length; i++) {
            const key = Object.keys(this.state.hours[i]);
            if(key[0] == dow) {
                hours = this.state.hours[i][dow];
            }
        }

        // Add break to hours
        hours.forEach(hour => {
            console.log(hour);
            var end = moment(hour.start, "HH:mm");
            //calculate 30min
            var h = moment.duration(30,'minutes');
            // calculate the duration
            var d = moment.duration(end);
            // subtract the lunch break
            d.subtract(15, 'minutes');
            // format a string result
            let s = moment(end + h).format('HH:mm');
            hour.endToShow = hour.end;
        });

        this.state.activeReservations.forEach(activeReservation => {
            let startAt = moment(activeReservation.start_at, "HH:mm:ss").format('HH:mm')
            let endAt = moment(activeReservation.end_at, "HH:mm:ss").format('HH:mm')
            hours.forEach(hour => {
                if(hour.start == startAt && hour.end == endAt) {
                    hour.disabled = true;
                }
            });
        });

        this.setState({
            listHours: hours.map((hour) =>
                <option disabled={hour.disabled} key={hour.start + "-" + hour.end} value={hour.start + "-" + hour.end}>{hour.start} - {hour.endToShow} uur</option>
            )
        });
    }

    setClownWorkingHours = (clown) => {
        // get the video chat dates and hours of the selected clown
        let videoChatData = JSON.parse(clown.clown.video_hours);
        let videoChatDays = [];
        let videoChatHours = [];

        const keys = Object.keys(videoChatData)
        for (const key of keys) {
            if(!isObjEmpty(videoChatData[key])) {
                videoChatDays.push(key);
                videoChatHours.push({[key]: videoChatData[key]});
            }
        }

        this.setState({
            // Populate working days and hours
            days: videoChatDays,
            hours: videoChatHours,
        });
    }

    getSelectedClown = (id) => {
        // get selected clown from array of clowns
        let selected;
        this.state.clowns.forEach(clown => {
            if(clown.id == id) {
                selected = clown;
            }
        });
        return selected;
    }

    prepareDatesByClown = (id) => {
        let selected;
        if (this.state.clowns.length > 1)
        {
            selected = this.getSelectedClown(id);
            this.setState({
                selectedClown: selected
            })
            this.setClownWorkingHours(selected);
        }
        else{
            selected = this.state.clowns.id;
            this.setClownWorkingHours(this.state.clowns);
        }
        //this.setClownWorkingHours(selected);

        this.setState({
            // Agenda data needed
            listDates: [],
            listHours: [],
            // Input Values
            dateValue: "",
            hourValue: "",
            // Values to send via api
            clown_id: id,
            date: "",
            start_at: "",
            end_at: ""
        })
    }

    prepareHoursByDate = (date) => {
        this.setState({
            dateValue: date,
            // Agenda data needed
            listHours: [],
            // Input Values
            hourValue: "",
            // Values to send via api
            date: date,
            start_at: "",
            end_at: ""
        });
    }

    prepareStartEndHours = (hours) => {
        let hoursArray = hours.split("-");
        this.setState({
            hourValue: hours,
            // Values to send via api
            start_at: hoursArray[0],
            end_at: hoursArray[1]
        })
    }

    async handleClownSelectChange(event) {
        await this.prepareDatesByClown(event.target.value);
        await this.buildDates();
        this.buildClowns();
        this.setState({slider: <Slider {...slickOptions}>{this.state.listClowns}</Slider>});
        // console.log(this.state.clown_id);
    }

    async handleClownDates(id) {
        await this.prepareDatesByClown(id);
        await this.buildDates();
        this.buildClowns();
        this.setState({slider: <Slider {...slickOptions}>{this.state.listClowns}</Slider>});
        // console.log(this.state.clown_id);
    }

    async handleDateSelectChange(event) {
        await this.prepareHoursByDate(event.target.value);
        await this.buildHours(this.state.dateValue);
    }

    async handleHourSelectChange(event) {
        await this.prepareStartEndHours(event.target.value);
    }

    deleteReservation = () => {
        if(this.user) {
            let data = {user_id: this.user.id, reservation_id: this.state.reservation[0].id}
            destroyReservation(data, this.user.token).then(res => {
                if(res.success) {
                    // Push to confirmation screen
                    this.props.history.push({pathname: '/'});
                }
            })
        }
    }

    render () {
        const gettingRes = this.state.gettingRes;
        let clownheader;
        if (this.state.clowns.length > 1) {
            clownheader = LANG.CLOWNS_HEADER;
        } 
        else {
            clownheader = LANG.CLOWN_HEADER;
        }
        const form = (
            <form className="form" onSubmit={this.onSubmit}>
                <p className="agenda__title">{clownheader}</p>
                {this.state.slider}
                <p className="selected__clown_center">{this.state.selectedClown ? "Gekozen clown: " + this.state.selectedClown.clown.nickname : <br/>}</p>
                <p className="agenda__title">{LANG.DATE_HEADER}</p>
                <div className="input__field">
                    <select 
                        className="input select"
                        name="date" 
                        value={this.state.dateValue} 
                        onChange={this.handleDateSelectChange}>
                        <option value=""> - </option>
                        {this.state.listDates}
                    </select>
                </div>
                <p className="agenda__title">{LANG.TIME_HEADER}</p>
                <p>{gettingRes ? LANG.GETTING_HOURS : ''}</p>
                <div className="input__field">
                    <select 
                        className="input select"
                        name="hour" 
                        value={this.state.hourValue}
                        onChange={this.handleHourSelectChange}>
                        <option value=""> - </option>
                        {this.state.listHours}
                    </select>
                </div>
                <div className="app__btn__group">
                    <button 
                        className="app__btn app__btn--state-normal"
                        type="submit" >
                        {LANG.RESERVATION_BTN}
                    </button>
                </div>
            </form>
        )
        const reservation = (
            <div>
                <p className="reservation__reminder">{(this.state.daysLeft == 0) ? LANG.RES_TODAY : LANG.RES_IN + this.state.daysLeft + LANG.DAYS} </p>
                <div className="reservation__card">
                    <div className="reservation__card--clown reservation__card--clown-image">
                        <img src={config.server +''+ this.state.clownAvatar} />
                    </div>
                    <div className="reservation__card--clown  reservation__card--clown-info">
                        <h5 className="reservation__name">Clown {this.state.clownNickname}</h5>
                        <p className="reservation__time">{this.state.reservationDate}</p>
                        <p className="reservation__time">{this.state.start_at + ' - ' + this.state.end_at + LANG.HOURS}</p>
                    </div>
                </div>
                {(this.state.reservation.locked == null) ? 
                <div className="app__btn__group">
                    <button 
                        onClick={() => this.deleteReservation()}
                        className="app__btn app__btn--state-outline">
                        {LANG.DELETE_RESERVATION_BTN}  
                    </button>
                </div> : <div></div> }
                
            </div>
        )
        if(this.state.reservation.length === 0) {
            return (
                <Page className="">
                    <ScreenNav 
                        title={LANG.CALENDAR}
                        back={'/'}
                        imageSrc={'/assets/illustrations/caravan.svg'}
                    />
                    <div className="container">
                        {form}
                    </div>
                </Page>
            )
        } else {
            return (
                <Page className="">
                    <ScreenNav 
                        title={LANG.CALENDAR}
                        back={'/'}
                    />
                    <div className="container">
                        {reservation}
                    </div>
                </Page>
            )
        }
    }
}

export default Agenda