// Core
import React, { Component } from 'react'
import { Link } from 'react-router-dom'
// Components
import ScreenNav from '../Navigation/ScreenNav';
// Page Layout
import Page from '../Layout/Page';
// Helpers
import { checkLanguage, getClowns } from '../../helpers/Functions';
// Config
import config from '../../config/Config';
// Style
import './Clowns.scss'
// Globals
let LANG;



class Clowns extends Component {
    constructor(props) {
        super(props)
        this.state = {
            clowns: []
        }

        // get copy in chosen language
        LANG = checkLanguage();
    }

    componentDidMount = () => {
        if(!LANG) {
            this.props.history.push({pathname: '/language', state: { second: true }});
        }
        getClowns().then(res => {
            var newClownList = [];
            res.data.forEach(clown => {
                if (clown.id === 8 || clown.id === 44 || clown.id === 45){
                    newClownList.push(clown);
                }
            })
            res.data.forEach(clown => {
                if (clown.id !== 8 && clown.id !== 44 && clown.id !== 45){
                    newClownList.push(clown);
                }
            })
            this.setState({
                clowns: newClownList
            })
        });
    }

    toTopLevel = () => {
        document.querySelector('.page').style.transform = "translateX(-100%)";
    }
    
    render () {
        const listClowns = this.state.clowns.map((clown) =>
            <li key={clown.id} className="clowns__item">
                <Link 
                    className="item__link"
                    onClick={() => this.toTopLevel()}
                    to={{ pathname: '/clowns/'+clown.id, state: { third: true } }}>
                    <img className="clown__avatar" src={config.server+clown.clown.avatar_app} href={clown.clown.nickname} />
                </Link>
            </li>
        );
        // get copy in chosen language
        return (
            <Page className="">
                <ScreenNav 
                    title={LANG.CLOWNS}
                    back={'/'}
                    imageSrc={'/assets/illustrations/clowns.svg'}
                />
                <div className="container clowns">
                    <ul className="clowns__list">
                        {listClowns}    
                    </ul>
                </div>
            </Page>
        )
    }
}

export default Clowns