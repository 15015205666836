// Core
import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import Slider from "react-slick";
// Components
import ScreenNav from '../Navigation/ScreenNav';
// Page Layout
import Page from '../Layout/Page';
// Helpers
import { checkLanguage, checkAuth, getGuestbookMessages, getUserGuestbookMessages } from '../../helpers/Functions';
// Style
import './GuestBook.scss'
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
// Globals
let LANG;
let user;
const sliderSettings = {
    className: "center",
    centerMode: true,
    infinite: false,
    centerPadding: "30px",
    slidesToShow: 1,
    arrows: false,
    speed: 500,
    slider: null,
    // active state
    allUsers: true,
    oneUser: false,
  };

class GuestBook extends Component {
    constructor() {
        super()
        this.state = {
            guestbook: [],
            slider: null
        }

        // get copy in chosen language
        LANG = checkLanguage();
        // get auth user
        user = checkAuth();
    }

    showAllMessages = async () => {
        await getGuestbookMessages(user.token).then(res => {
            this.setState({
                guestbook: res.data.map((guestbook) =>
                    <div key={guestbook.id} className="message__container">
                        <div className="message__content">
                            <div className="message__text">
                                <p>{guestbook.message}</p>
                            </div>
                            <div className="writer">
                                <div className="message__user">
                                    <i className="icon--state-normal icon--type-user"></i>
                                    <p className="message__user--name">{guestbook.user.name}</p>
                                </div>
                                <div className="message__date"><span>{guestbook.date}</span></div>
                            </div>
                        </div>
                        <div className="message__background"></div>
                    </div>
                ),
                allUsers: true,
                oneUser: false
            })
        });
        await this.setState({slider: <Slider {...sliderSettings}>{this.state.guestbook}</Slider>});
    }

    showUserMessages = async () => {
        await getUserGuestbookMessages(user.id, user.token).then(res => {
            this.setState({
                guestbook: res.data.map((guestbook) =>
                    <div key={guestbook.id} className="message__container">
                        <div className="message__content">
                            <div className="message__text">
                                <p>{guestbook.message}</p>
                            </div>
                            <div className="writer">
                                <div className="message__user">
                                    <i className="icon--state-normal icon--type-user"></i>
                                    <p className="message__user--name">{guestbook.user.name}</p>
                                </div>
                                <div className="message__date"><span>{guestbook.date}</span></div>
                            </div>
                        </div>
                        <div className="message__background"></div>
                    </div>
                ),
                allUsers: false,
                oneUser: true
            });
        });
        await this.setState({slider: <Slider {...sliderSettings}>{this.state.guestbook}</Slider>});
    }

    componentDidMount = async () => {
        if(!LANG) {
            this.props.history.push({pathname: '/language', state: { second: true }});
        } else {
            if(!user) {
                this.props.history.push({pathname: '/login', state: { second: true }});
            } else {
                this.showAllMessages();
            }
        }
    }

    toTopLevel = () => {
        document.querySelector('.page').style.transform = "translateX(-100%)";
    }
    
    render () {
        // get copy in chosen language
        return (
            <Page className="">
                <ScreenNav 
                    title={LANG.GUESTBOOK}
                    back={'/'}
                    imageSrc={'/assets/illustrations/parasol.svg'}
                />  
                <div className="questbook">
                    <div className="guestbook__tabs">
                        <button onClick={() => this.showAllMessages()} class={this.state.allUsers ? 'tab tab--state-selected' : 'tab'}>{LANG.ALL_MESSAGES}</button>
                        <button onClick={() => this.showUserMessages()} class={this.state.oneUser ? 'tab tab--state-selected' : 'tab'}>{LANG.MY_MESSAGES}</button>
                    </div>
                    <div className="guestbook__container">
                        {this.state.slider}
                    </div>
                    <div className="app__btn__group container pt-0">
                        <Link 
                            className="app__btn app__btn--state-normal"
                            onClick={() => this.toTopLevel()}
                            to={{ pathname: '/guestbook/send-message', state: { third: true } }}>
                            {LANG.SEND_MESSAGE}
                        </Link>
                    </div>
                </div>
            </Page>
        )
    }
}

export default GuestBook