// Core
import React, { Component } from 'react'
import { toast } from 'react-toastify';
import axios from 'axios'
// Components
import ScreenNav from '../Navigation/ScreenNav';
// Page Layout
import Page from '../Layout/Page';
// Helpers
import { checkLanguage } from '../../helpers/Functions';
// Config
import config from '../../config/Config';
// Style
import './Auth.scss';
import 'react-toastify/dist/ReactToastify.min.css';
// Globals
let LANG;
const toastOptions = {
    autoClose: 6000,
    type: toast.TYPE.ERROR,
    position: toast.POSITION.BOTTOM_CENTER,
    draggablePercent: 60
};

class PasswordReset extends Component {

    constructor() {
        super()
        this.state = {
            email: '',
            secret: 'resetpasswordemail',
        }

        // bind functions to buttons and input fields
        this.onChange = this.onChange.bind(this)
        this.onSubmit = this.onSubmit.bind(this)
        // get copy in chosen language
        LANG = checkLanguage();
    }

    onChange(e) {
        this.setState({ 
            [e.target.name]: e.target.value 
        })
    }

    onSubmit(e) {
        // Prevent default
        e.preventDefault();
        // Get this to use later on this function
        let self = this;
        // API post request
        axios.post(config.server+'api/users/password/reset', {
            email: this.state.email,
            secret: this.state.secret
        })
        .then(function (response) {
            self.onResponse(true);
        })
        .catch(function (error) {
            if("errors" in error.response.data) {
                self.onResponse(false, Object.values(error.response.data.errors)[0], false);
            } else if("message" in error.response.data) {
                self.onResponse(false, Object.values(error.response.data.message)[0], false);
            } else {
                self.onResponse();
            }
        });
    }

    onResponse = (status, response, data) => {
        let s = status || false;
        let r = response || LANG.PASS_ERROR;
        let d = data || false;
        if(s) {
            // Push to confirmation screen
            this.props.history.push({pathname: '/password/reset/confirmation', state: { success: true }})
        } else {
            // console.log(toast);
            let toastId = null
            toast.dismiss(this.toastId);
            toastId = toast(""+r, toastOptions)
        }
    }

    componentDidMount = () => {
        if(!LANG) {
            this.props.history.push('/language');
        }
    }

    render () {
        return (
            <Page>
                <ScreenNav 
                    title={LANG.CONFIRMATION}
                    back={'/login'}
                />
                <div className="container">
                    <form className="form" noValidate onSubmit={this.onSubmit}>

                        <div className="input__field">
                            <i className="icon icon--type-mail"></i>
                            <input 
                                className="input"
                                type="email"
                                name="email"
                                placeholder={LANG.EMAIL_FIELD}
                                value={this.state.email}
                                onChange={this.onChange} />
                        </div>

                        <div className="app__btn__group">
                            <button 
                                className="app__btn app__btn--state-normal"
                                type="submit" >
                                {LANG.RESET_PASSWORD}
                            </button>
                        </div>
                    </form>
                </div>
            </Page>
        )
    }
}

export default PasswordReset