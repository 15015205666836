// Core
import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'
// Custom Components
import SideBar from '../Layout/SideBar/SideBar'
import Header from '../Layout/Header/Header'
import Footer from '../Layout/Footer/Footer'
import Table from '../Layout/Table/Table'
// Style
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import '../ClownAdmin.scss';
import './Agenda.scss';
// Helpers
import { checkLanguage, getReservationsByClownBetweenDates } from '../../../helpers/Functions';
// Config
import config from '../../../config/Config';
// Globals
let LANG;

class Agenda extends Component {
    constructor(props) {
        super(props)
        this.state = {
            reservations: []
        }
        // Static variables
        this.user = JSON.parse(localStorage.getItem('loggedIn')) || null;
        LANG = checkLanguage();
    }

    componentDidMount = () => {
        if(!LANG) {
            this.props.history.push({pathname: '/language', state: { second: true }});
        }
        if(this.user) {
            getReservationsByClownBetweenDates(this.user.id, this.user.token).then(res => {
                if(res.success) {
                    this.setState({
                        reservations: res.data
                    })
                    console.log(res.data);
                }
            })
        }
    }
    
    render () {
        return this.user ? (

            <div className="container-scroller">
                <Header 
                    title="Agenda"
                    name={this.user.nickname}
                    avatar={config.server+this.user.avatar} 
                    />

                <div className="container-fluid page-body-wrapper">
                    <SideBar 
                        name={this.user.nickname}
                        avatar={config.server+this.user.avatar}  
                        />

                    <div className="main-panel">
                        <div className="content-wrapper">
                            {!this.state.reservations.length > 0 ? LANG.CLOWN_EMPTY_CALENDAR : this.state.reservations.map((reservation) =>
                                <div key={reservation.id} className="page-content">
                                    <div className="page-header">
                                        <h3 className="page-title">
                                        <span className="page-title-icon bg-gradient-primary text-white mr-2">
                                            <i className="mdi mdi-calendar-clock"></i>                 
                                        </span>
                                        {reservation[0]['date']}
                                        </h3>
                                    </div>
                
                                    <Row>
                                        <Col
                                            className="grid-margin" >
                
                                            <div className="card">
                                                <div className="card-body">
                                                    <h4 className="card-title">{LANG.CLOWN_CAL}</h4>
                                                    <div className="custom-table-responsive table-responsive">
                                                        <Table className="table-responsive"
                                                            data={reservation}
                                                            user={this.user}
                                                            />
                                                    </div>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>)}
                            </div>
                        <Footer />

                    </div>
                </div>
            </div>

        ) : <Redirect to="/clown/login" />
    }
}

export default Agenda