// Core
import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch, Link } from 'react-router-dom';
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { ToastContainer } from 'react-toastify';
import Pusher from 'pusher-js';
// Config
import config from './config/Config';
// Components
// Helpers
import { checkLanguage, PrivateClownRoute, PrivateUserRoute, PrivateRoute } from './helpers/Functions';
// User Screens
import Home from './components/Home/Home'
import Language from './components/Language/Language'
import UserLogin from './components/Auth/UserLogin'
import Clowns from './components/Clowns/Clowns'
import Clown from './components/Clowns/Clown'
import Register from './components/Auth/Register'
import RegisterConfirmation from './components/Auth/RegisterConfirmation'
import PasswordReset from './components/Auth/PasswordReset'
import PasswordResetConfirmation from './components/Auth/PasswordResetConfirmation'
import Videos from './components/Videos/Videos'
import AgendaConfirmation from './components/Agenda/AgendaConfirmation'
import Agenda from './components/Agenda/Agenda'
import GuestBook from './components/GuestBook/GuestBook'
import SendMessage from './components/GuestBook/SendMessage'
import Videochat from './components/Videochat/Videochat'
import Settings from './components/Settings/Settings'
// Clown Screens
import ClownLogin from './components/ClownAdmin/Auth/Login'
import ClownAgenda from './components/ClownAdmin/Agenda/Agenda'
import ClownGuestBook from './components/ClownAdmin/Guestbook/Guestbook'
// 404 Screen
import Error404 from './components/Error/Error404'
// import style
import './App.scss'
import './reset.css'
// Globals
let LANG;

// Does the user's browser support the HTML5 history API?
// If the user's browser doesn't support the HTML5 history API then we
// will force full page refreshes on each page change.
const supportsHistory = "pushState" in window.history;

class App extends Component {
  constructor(props) {
    super(props)
    this.state = {
        notification: null,
        lastKnownUser: null
    }
    // get copy in chosen language
    LANG = checkLanguage();
    // Static variables
    this.user = JSON.parse(localStorage.getItem('login')) || null;
    // Functions 
    this.connectUserToPresenceChannel();
    
  }

  componentDidMount = () => {
    //Sounds
    this.audioRing = new Audio("/assets/sounds/ringtone.mp3");
    this.audioRing.loop = true;
    this.audioRing.volume = 0.7;

  //   Notification.requestPermission(function(status) {
  //     console.log('Notification permission status:', status);
  // });
}

// displayNotification = () => {
//   if (Notification.permission == 'granted') {
//     navigator.serviceWorker.getRegistration().then(function(reg) {
//       var options = {
//         body: LANG.PUSH_NOTIFICATION_BODY,
//         icon: 'assets/pwa/icons/android-chrome-512x512.png',
//         vibrate: [100, 50, 100],
//         data: {
//           dateOfArrival: Date.now(),
//           primaryKey: 1
//         }
//       };
//       reg.showNotification('Clinicam', options);
//     });
//   }
// }

  // Connect to presence channel
  connectUserToPresenceChannel = () => {
    // ! Enable pusher logging - don't include this in production
    Pusher.logToConsole = false;
    // Puser credentials
    let lastKnownUser = this.state.lastKnownUser;
    if(this.user) {
      let pusher = new Pusher(config.pusher_key, {
        cluster: 'eu',
        authEndpoint: `${config.server}api/pusher/auth/${this.user.id}`,
        forceTLS: true,
      });
      // Connect to a presence channel
      this.presenceChannel = pusher.subscribe(`presence-user-${this.user.id}`);
      // If connected
      this.presenceChannel.bind('pusher:subscription_succeeded', () => {
          // Bind client call event to be called by clown
          this.presenceChannel.bind('client-call', (data) => {
            this.audioRing.play();
            //this.displayNotification();
              this.setState({
                  lastKnownUser: this.user.id,
                  notification: 
                  <div className="videochat__container">
                      <div className="videochat__notification">
                          {/* <h2 className="videochat__title">{LANG.CALL}</h2> */}
                          <div className="videochat__content">
                              <img alt="call icon" src="/assets/illustrations/call.svg" />
                              <h1 className="videochat__message">Clown {data.nickname} {LANG.IS_CALLING}</h1>
                          </div>
                          <div className="videochat__controllers">
                              <a href="#" onClick={() => this.rejectIncomingCall()}>
                                  <img alt="reject call icon" src="/assets/illustrations/call-deny.svg" />
                                  <span className="deny">{LANG.DENY}</span>
                              </a>
                              <Link 
                                  onClick={() => this.acceptIncomingCall()}
                                  to={{ pathname: '/videochat/', state: { second: true, videochat: data.videochat } }}>
                                  <img alt="answer call icon" src="/assets/illustrations/call-answer.svg" />
                                  <span className="accept">{LANG.ACCEPT}</span>
                              </Link>
                          </div>
                      </div>
                  </div>
              })
          });
      });
    }
    else {
      let pusher = new Pusher(config.pusher_key, {
        cluster: 'eu',
        authEndpoint: `${config.server}api/pusher/auth/${lastKnownUser}`,
        forceTLS: true,
      });
      this.presenceChannel = pusher.unsubscribe(`presence-user-${lastKnownUser}`);
    }
  }

  // Call deny
  rejectIncomingCall() {
    this.audioRing.pause();
      // Inform clown of rejection
      this.presenceChannel.trigger('client-reject', { 
          success: false, 
          userName: this.user.name 
      });
      // Remove notification
      this.setState({ notification: null })
  }

  // Call accept
  acceptIncomingCall() {
    this.audioRing.pause();
      // Remove notification
      this.setState({ notification: null })
  }
  
  
  render () {
    return (
      <div>
      <Router forceRefresh={!supportsHistory}>
        <div className="App">
          <Route
            render={({ location }) => {
              const { pathname } = location;
              return (
                <TransitionGroup>
                  <CSSTransition key={pathname} classNames="page" timeout={{ enter: 250, exit: 250 }}>
                    <Route
                      location={location}
                      render={() => (
                        <Switch>
                          {/* Private Routes Kids */}
                            {/* Agenda */}
                            <PrivateUserRoute exact path="/agenda/confirmation" component={AgendaConfirmation} />
                            <PrivateUserRoute exact path="/agenda" component={Agenda} />
                            {/* Guestbook */}
                            <PrivateUserRoute exact path="/guestbook/send-message" component={SendMessage} />
                            <PrivateUserRoute exact path="/guestbook" component={GuestBook} />
                          {/* Private Routes Clowns */}
                            {/* Agenda */}
                            <PrivateClownRoute exact path="/clown/agenda" component={ClownAgenda} />
                            {/* Guestbook */}
                            <PrivateClownRoute exact path="/clown/guestbook" component={ClownGuestBook} />
                          {/* Private Routes Kids and Clowns */}
                            {/* Videochat */}
                            <PrivateRoute exact path="/videochat" component={Videochat} />

                          {/* Global routes */}
                            <Route exact path="/" component={Home} />
                            {/* Auth Clown */}
                            <Route exact path="/clown/login" component={ClownLogin} />
                            {/* Auth Kid */}
                            <Route path="/language" component={Language} />
                            <Route path="/login" component={UserLogin} />
                            <Route path="/register/confirmation" component={RegisterConfirmation} />
                            <Route path="/register" component={Register} />
                            <Route path="/password/reset/confirmation" component={PasswordResetConfirmation} />
                            <Route path="/password/reset" component={PasswordReset} />
                            {/* Clowns */}
                            <Route path="/clowns/:id" component={Clown} />
                            <Route path="/clowns" component={Clowns} />
                            {/* Videos */}
                            <Route path="/videos" component={Videos} />
                            {/* Settings */}
                            <Route path="/settings" component={Settings} />
                            {/* Error */}
                            <Route component={Error404} />
                        </Switch>
                      )}
                    />
                  </CSSTransition>
                </TransitionGroup>
              );
            }}
          />
          {/* Calling notification */}
          {this.state.notification}
        </div>
      </Router>
      <ToastContainer 
        className="custom__toast"
      />
    </div>
    );
  }
}

export default App;
