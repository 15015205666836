// Core
import React, { Component } from 'react'
// Components
import ScreenNav from '../Navigation/ScreenNav';
// Page Layout
import Page from '../Layout/Page';
// Helpers
import { checkLanguage } from '../../helpers/Functions';
// Style
import './Error404.scss'
// Globals
let LANG;

class Error404 extends Component {
    constructor(props) {
        super(props)
        // get copy in chosen language
        LANG = checkLanguage();
    }
    
    componentDidMount = () => {
        if(!LANG) {
            this.props.history.push({pathname: '/language', state: { second: true }});
        }
    }

    render () {
        // get copy in chosen language
        return (
            <Page className="">
                <ScreenNav 
                    title={'404'}
                    back={'/'}
                />  
                <div className="container error-404">
                    <h2>{LANG.ERROR_NF}</h2>
                </div>
            </Page>
        )
    }
}

export default Error404