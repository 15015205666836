import React, { Component } from 'react'
import { Link, withRouter } from 'react-router-dom'
import './Navbar.scss'

class Navbar extends Component {
    constructor(props){ 
        super(props)
    }


    scrollToElement = (element) => {
        let duration = 500
        let elementPosition = element.current.offsetTop;
        let elementHeight = element.current.clientHeight;
        // !old this.props.page.current.parentNode.scrollTo({top: elementPosition - elementHeight, left: 0, behavior: 'smooth'});
        this.scrollTo(this.props.page.current.parentNode, elementPosition - elementHeight, duration);
        setTimeout(function() {
            element.current.style.transform = 'scale(1.2)';
            setTimeout(function() {
                if(element.current) {
                    element.current.style.transform = 'scale(1)';
                }
            }, 250)
        }, duration + 100)
    }

    scrollTo = (element, to, duration) => {
        let start = element.scrollTop,
            change = to - start,
            currentTime = 0,
            increment = 20;
        let self = this;
        let animateScroll = function(){        
            currentTime += increment;
            let val = self.something(currentTime, start, change, duration);
            element.scrollTop = val;
            if(currentTime < duration) {
                setTimeout(animateScroll, increment);
            }
        };
        animateScroll();
    }

    something = (currentTime, start, change, duration) => {
        currentTime /= duration/2;
        if (currentTime < 1) return change/2*currentTime*currentTime + start;
        currentTime--;
        return -change/2 * (currentTime*(currentTime-2) - 1) + start;
    }
    
    render() {
        var isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
        const userNav = (
            <ul className="navbar-navigation">
                <li className="navigation-item">
                    <a href="#" onClick={() => this.scrollToElement(this.props.clowns)} className="navigation-link navigation-clown">
                        <img className="custom-icon-large" src="/assets/icons/clown.svg" alt="navigatie clowns"></img>
                    </a>
                </li>
                <li className="navigation-item">
                    <a href="#" onClick={() => this.scrollToElement(this.props.videos)} className="navigation-link navigation-clown">
                        <img className="custom-icon-large" src="/assets/icons/videos.svg" alt="navigatie video's"></img>
                    </a>
                </li>
                <li className="navigation-item">
                    <a href="#" onClick={() => this.scrollToElement(this.props.videochat)} className="navigation-link navigation-clown">
                        <img className="custom-icon-large" src="/assets/icons/cam.svg" alt="navigatie webcam"></img>
                    </a>
                </li>
                <li className="navigation-item">
                    <a href="#" onClick={() => this.scrollToElement(this.props.agenda)} className="navigation-link navigation-clown">
                        <img className="custom-icon-large" src="/assets/icons/agenda.svg" alt="navigatie agenda"></img>
                    </a>
                </li>
                <li className="navigation-item">
                    <a href="#" onClick={() => this.scrollToElement(this.props.guestbook)} className="navigation-link navigation-clown">
                        <img className="custom-icon-large" src="/assets/icons/gastenboek.svg" alt="navigatie gastenboek"></img>
                    </a>
                </li>
                <li className="navigation-item">
                    <a href="#" onClick={() => this.scrollToElement(this.props.settings)} className="navigation-link navigation-clown">
                        <img className="custom-icon-large" src="/assets/icons/settings.svg" alt="navigatie instellingen"></img>
                    </a>
                </li>
            </ul>
        )
        if (isMobile){
            return (
                <nav className="main-navigation">    
                    {userNav}
                </nav>
            )
        }
        else{
            return (
                null
            )
        }
    }
}

export default withRouter(Navbar)