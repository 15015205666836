// Core
import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'
// Custom Components
import SideBar from '../Layout/SideBar/SideBar'
import Header from '../Layout/Header/Header'
import Footer from '../Layout/Footer/Footer'
// Style
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import '../ClownAdmin.scss';
import './Guestbook.scss';
// Helpers
import { checkLanguage, getGuestbookMessages } from '../../../helpers/Functions';
// Config
import config from '../../../config/Config';
// Globals
let LANG;

class Guestbook extends Component {
    constructor(props) {
        super(props)
        this.state = {
            messages: []
        }
        // Static variables
        this.user = JSON.parse(localStorage.getItem('loggedIn')) || null;
        LANG = checkLanguage();
    }

    componentDidMount = () => {
        if(!LANG) {
            this.props.history.push({pathname: '/language', state: { second: true }});
        }
        if(this.user) {
            getGuestbookMessages(this.user.token).then(res => {
                if(res.success) {
                    this.setState({
                        messages: res.data
                    })
                }
            })
        }
    }
    
    render () {
        return this.user ? (

            <div className="container-scroller">
                <Header 
                    title="Guestbook"
                    name={this.user.nickname}
                    avatar={config.server+this.user.avatar} />

                <div className="container-fluid page-body-wrapper">
                    <SideBar 
                        name={this.user.nickname}
                        avatar={config.server+this.user.avatar} />

                        <div className="main-panel">
                            <div className="content-wrapper">
                                <div className="page-header">
                                    <h3 className="page-title">
                                        <span className="page-title-icon bg-gradient-primary text-white mr-2">
                                            <i className="mdi mdi-book-open-page-variant"></i>                 
                                        </span>
                                        {LANG.CLOWN_GB_OVERVIEW}
                                    </h3>
                                </div>
                                <div className="page-content">
                                    <Row>
                                        <Col className="grid-margin" >
                                            <div className="card">
                                                <div className="card-body">
                                                    <h4 className="card-title">{LANG.CLOWN_GUESTBOOK}</h4>
                                                    {this.state.messages.map((message) =>
                                                        <blockquote key={message.id} className="clown__guestbook__message blockquote blockquote-primary">
                                                            <p>{message.message}</p>
                                                            <footer className="blockquote-footer">{LANG.CLOWN_GB_WRITTENBY} <cite title={message.user.name}>{message.user.name} - {message.date}</cite></footer>
                                                        </blockquote>
                                                    )}
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </div>

                        <Footer />
                    </div>
                </div>
            </div>

        ) : <Redirect to="/clown/login" />
    }
}

export default Guestbook