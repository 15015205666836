let config = {
    // Backend server
    // put a "/" at the end. DO NOT FORGET
    // server: "http://127.0.0.1:8000/",
    server: "https://admin.clinicam.be/",
    // server: "https://startupbooster.be/",

    // Youtube
    youtube_key: "AIzaSyAfHgHXS6OZHEsOesKW1o95NnjWooW-YV8",
    channel_id: "UCThPycEFi_e2MkdQ1OLRdzA",

    //turn server
    usernameT: "cliniclown",
    passwordT: "megaman4",

    // Pusher - WebSockets
    pusher_key: "43a8a9536d8cecce279e",
}

export default config;