// Core
import React, { Component } from 'react'
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom'
import axios from 'axios'
// Components
import ScreenNav from '../Navigation/ScreenNav';
// Page Layout
import Page from '../Layout/Page';
// Helpers
import { checkLanguage, checkAuth } from '../../helpers/Functions';
// Config
import config from '../../config/Config';
// Style
import './Auth.scss'
import 'react-toastify/dist/ReactToastify.min.css';
// Globals
let LANG;
// Variables
let user;
const toastOptions = {
    autoClose: 6000,
    type: toast.TYPE.ERROR,
    position: toast.POSITION.BOTTOM_CENTER,
    draggablePercent: 60
};

class UserLogin extends Component {

    constructor() {
        super()
        this.state = {
            email: '',
            password: ''
        }

        // bind functions to buttons and input fields
        this.onChange = this.onChange.bind(this)
        this.onSubmit = this.onSubmit.bind(this)
        // get copy in chosen language
        LANG = checkLanguage();
        // get auth user
        user = checkAuth();
    }

    onChange(e) {
        this.setState({ 
            [e.target.name]: e.target.value 
        })
    }

    onSubmit(e) {
        // Prevent default
        e.preventDefault();
        // Get this to use later on this function
        let self = this;
        // API post request
        axios.post(config.server+'api/users/login', {
            email: this.state.email,
            password: this.state.password
        })
        .then(function (response) {
            self.onResponse(true, response.data.message, response.data.data);
        })
        .catch(function (error) {
            if("errors" in error.response.data) {
                self.onResponse(false, Object.values(error.response.data.errors)[0], false);
            } else if("message" in error.response.data) {
                self.onResponse(false, Object.values(error.response.data.message)[0], false);
            } else {
                self.onResponse();
            }
        });
    }

    onResponse = (status, response, data) => {
        let s = status || false;
        let r = response || "Er is iets fout gegaan";
        let d = data || false;
        if(s) {
            localStorage.setItem('login', JSON.stringify(d));
            window.location.reload();
            //this.props.history.push('/');
        } else {
            // console.log(toast);
            let toastId = null
            toast.dismiss(this.toastId);
            this.toastId = toast(""+r, toastOptions)
        }
    }

    componentDidMount = () => {
        if(!LANG) {
            this.props.history.push({pathname: '/language', state: { second: true }});
        }
        if(user) {
            this.props.history.push({pathname: '/', state: { first: true }});
        }
    }

    render () {
        return (
            <Page className="second">
                <ScreenNav 
                    title={LANG.LOGIN}
                    back={'/'}
                />
                <div className="container">
                    <form className="form" noValidate onSubmit={this.onSubmit}>
                        <div className="input__field">
                            <i className="icon icon--type-user"></i>
                            <input 
                                className="input"
                                type="email"
                                name="email"
                                placeholder={LANG.EMAIL_FIELD}
                                value={this.state.email}
                                onChange={this.onChange}
                            />
                        </div>
                        <div className="input__field">
                            <i className="icon icon--type-password"></i>
                            <input 
                                className="input"
                                type="password"
                                name="password"
                                placeholder={LANG.PASSWORD_FIELD}
                                value={this.state.password}
                                onChange={this.onChange}
                            />
                        </div>
                        <Link 
                            className="primary-link secondary-link"
                            to={{ pathname: '/password/reset', state: { middle: true } }}>
                            {LANG.FORGOT_PASSWORD}
                        </Link>
                        <div className="app__btn__group">
                            <button 
                                className="app__btn app__btn--state-normal"
                                type="submit" >
                                {LANG.LOGIN_BTN}
                            </button>
                        </div>
                        <div className="text--with-link">
                            <p>{LANG.REGISTER_QUESTION} </p>
                            <Link 
                                className=""
                                to={{ pathname: '/register', state: { middle: true } }}>
                                {LANG.REGISTER_BTN}
                            </Link>
                        </div>
                    </form>
                </div>
            </Page>
        )
    }
}

export default UserLogin