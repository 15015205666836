// Core
import React, { Component } from 'react'
import { Link } from 'react-router-dom'
// Components
import ScreenNav from '../Navigation/ScreenNav';
// Page Layout
import Page from '../Layout/Page';
// Helpers
import { checkLanguage, getClown } from '../../helpers/Functions';
// Config
import config from '../../config/Config';
// Style
// import '../../main.css'
// Globals
let LANG;



class Clown extends Component {
    constructor(props) {
        super(props)
        this.state = {
            avatar: "",
            nickname: "",
            description: "",
            clown_id: "",
            videoChat: false
        }
        
        // get copy in chosen language
        LANG = checkLanguage();
    }

    componentDidMount = async () => {
        if(!LANG) {
            this.props.history.push({pathname: '/language', state: { second: true }});
        }
        getClown(this.props.match.params.id).then(res => {
            let lang = localStorage.getItem('lang');
            let description = JSON.parse(res.data.clown.description);
            let videoChat = false;
            if(res.data.clown.video_hours !== `{"1": [], "2": [], "3": [], "4": [], "5": []}`) {
                videoChat = true;
            }
            this.setState({
                avatar: res.data.clown.avatar_app,
                nickname: res.data.clown.nickname,
                description: description[lang],
                clown_id: res.data.id,
                videoChat: videoChat
            })
            // console.log(this.state.clown_id);
        });
    }
    
    render () {
        // get copy in chosen language
        return (
            <Page className="">
                <ScreenNav 
                    title={'Clown ' + this.state.nickname}
                    back={'/clowns'}
                />
                <div className="container">
                    <div className="clown__avatar">
                        <img src={config.server+this.state.avatar} alt={this.state.nickname} />
                    </div>
                    <div className="clown__info">
                        <ul>
                            <li><i className="icon icon--state-relative icon--type-clown"></i><p>{this.state.nickname}</p></li>
                            {this.state.videoChat !== "" ? <li><i className="icon icon--state-relative icon--type-videochat"></i><p>Webcam</p></li> : null}
                        </ul>
                    </div>
                    <div className="clown__description">
                        <p className="clown__description--subtitle">{LANG.ABOUT_CLOWN} {this.state.nickname}</p>
                        <p className="clown__description--text">{this.state.description}</p>
                    </div>
                    {this.state.videoChat !== "" ? <div className="app__btn__group">
                        <Link 
                            className="app__btn app__btn--state-normal"
                            to={{ pathname: '/agenda/', state: { middle: true, sep_clown_id: this.state.clown_id } }}>
                            {LANG.MAKE_RESERVATION}
                        </Link>
                    </div> : null}
                </div>
            </Page>
        )
    }
}

export default Clown